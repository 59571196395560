import React, { Component, useState } from "react";
import "../Component/Lightbox";
import Lightbox from "../Component/Lightbox";
import S1 from "../speakers/1.jpg";
import S2 from "../speakers/2.jpg";
import S3 from "../speakers/3.jpg";
import S4 from "../speakers/4.jpg";
import S5 from "../speakers/5.jpg";
import S6 from "../speakers/6.jpg";
import S7 from "../speakers/7.jpg";
import S8 from "../speakers/8.jpg";
import S9 from "../speakers/9.jpg";
import S10 from "../speakers/10.jpg";
import GalleryModal from "../Component/GalleryModal";
 
const Speakers= () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgsrc, setImgSrc] = useState(false);
    const handleClick=(param)=> () => {

        setImgSrc(param);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
      };
        return (
            <><GalleryModal isOpen={isModalOpen} close={closeModal} imgsrc={imgsrc} />
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> SPEAKERS</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container-fluid py-lg-5 py-md-3">
                            <div className="row">
                                <div className="col-lg-12 themeheading">
                                    <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                                </div>
                                <div className="col-lg-12 pl-lg-5 mb-5">
                                    <div className="header-title">
                                        <span className="sub-title">SPEAKERS</span>

                                    </div>
<div className="row">
                                    <div className="col-md-4 gallery">
                                    <img
        src={S1}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S1)}
      />
      
                                    </div>


                                    <div className="col-md-4 gallery">
                                    <img
        src={S2}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S2)}
      />
     
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S3}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S3)}
      />
      
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S4}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S4)}
      />
      
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S5}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S5)}
      />
      
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S6}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S6)}
      />
    
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S7}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S7)}
      />
     
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S8}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S8)}
      />
     
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S9}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S9)}
      />
      
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={S10}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(S10)}
      />
      
                                    </div>

                                   

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
        }
export default Speakers;