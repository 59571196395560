import React, { Component, useState } from "react";
import Slider from "../Component/Slider";
import Place1 from "../images/place1.jpg";
import Place2 from "../images/place2.jpg";
import Place3 from "../images/place3.jpg";
import Place4 from "../images/place4.jpg";
import Place5 from "../images/place5.jpg";
import Place6 from "../images/place6.jpg";
import Place7 from "../images/place7.jpg";
import Place8 from "../images/place8.jpg";
import Place9 from "../images/place9.jpg";
import Cuisine1 from "../images/cuisine1.jpg";
import Cuisine2 from "../images/cuisine2.jpg";
import Cuisine3 from "../images/cuisine3.jpg";
import Cuisine4 from "../images/cuisine4.jpg";
import Cuisine5 from "../images/cuisine5.jpg";
import Cuisine6 from "../images/cuisine6.jpg";
import Shop1 from "../images/shop1.jpg";
import Shop2 from "../images/shop2.jpg";
import Shop3 from "../images/shop3.jpg";
import Shop4 from "../images/shop4.jpg";
import Ayodhya from "../images/ayodhya.jpg";
import Agra from "../images/agra.jpg";
import Varanasi from "../images/varanasi.jpg";
import UserImg from "../images/user.jpg";
import Modal from 'react-bootstrap/Modal';
import { NavLink, Link, useNavigate } from "react-router-dom";
export const Cuisines = () => {
    const [imambara, setImambara] = useState(false);
    const [janeshwarmishra, setJaneshwarmishra] = useState(false);
    const [Residency, setResidency] = useState(false);
    const [Rumygate, setRumygate] = useState(false);
    const [Echogarden, setEchogarden] = useState(false);
    const [Zoo, setZoo] = useState(false);
    const [Rama, setRama] = useState(false);
    const [Gomti, setGomti] = useState(false);
    const [Lulu, setLulu] = useState(false);
    return (
        <>

            <Modal
                size="lg"
                show={imambara}
                onHide={() => setImambara(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Bada Imambara
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place1} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">The Bara Imambara is an architectural marvel located in Lucknow, Uttar Pradesh, India. It was built by Nawab Asaf-ud-Daula in 1784 as a relief measure for a major famine that struck the region. The complex includes the Bara Imambara, the Asfi Mosque, and the Bhulbhulaiya (the Labyrinth).</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Here are some key features of the Bara Imambara:</p>

                            <p><b>1- Bara Imambara:</b> This is the main building and the largest arched construction without support beams. The central hall of the Imambara, known as the "Baradari," is one of the largest arched constructions of its kind.</p>

                            <p><b>2- Asfi Mosque:</b> The mosque is located to the west of the Imambara and is an integral part of the complex. It is also known as Asfi Masjid.</p>

                            <p> <b>3- Bhulbhulaiya (Labyrinth):</b> This is a maze of narrow, winding passages on the upper floor of the Imambara. It is said to be designed to confuse any intruder and is a popular attraction among visitors.</p>

                            <p><b>4- Gates:</b> The complex has several gates, including the Rumi Darwaza, which is an imposing gateway and an example of Awadhi architecture. The Rumi Darwaza is often considered the symbol of Lucknow.</p>

                            <p><b>5- Construction without pillars:</b> One of the unique features of the Bara Imambara is that it is one of the largest structures in the world without the support of beams or pillars. The central hall is said to be about 50 meters long and 15 meters high.</p>

                            <p><b>6- Famine relief project:</b> The construction of the Bara Imambara was initiated by Nawab Asaf-ud-Daula as a measure to provide employment to the people during a severe famine. The project aimed to provide sustenance to the people in exchange for their labor.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>




            <Modal
                size="lg"
                show={janeshwarmishra}
                onHide={() => setJaneshwarmishra(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Janeshwar Mishra Park
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place2} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Janeshwar Mishra Park is a large public park located in Lucknow, Uttar Pradesh, India. It is one of the newer parks in the city and is named after Janeshwar Mishra, a prominent politician and freedom fighter from Uttar Pradesh. The park is often referred to as J. P. Park and is known for its expansive green spaces, recreational facilities, and a variety of attractions.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features of Janeshwar Mishra Park:</p>

                            <p><b>1- Size and Layout:</b> The park is one of the largest in Asia, covering an area of over 376 acres. It is designed with a mix of green landscapes, water bodies, and recreational areas.</p>

                            <p><b>2- Amphitheater:</b> Janeshwar Mishra Park features an amphitheater where cultural events, performances, and public gatherings take place. It serves as a venue for various cultural and entertainment activities.</p>

                            <p> <b>3- Walking and Jogging Tracks:</b> The park provides well-maintained walking and jogging tracks, making it a popular spot for locals to engage in physical activities. The tracks wind through the greenery, offering a pleasant environment for exercise.</p>

                            <p><b>4- Lake and Boating:</b> There is a lake within the park where visitors can enjoy boating. The presence of water bodies adds to the overall ambiance of the park</p>

                            <p><b>5- Children's Play Area::</b> Janeshwar Mishra Park has designated areas for children to play, featuring playground equipment and recreational facilities suitable for kids.</p>

                            <p><b>6- Cycling Track:</b> The park includes a dedicated cycling track, encouraging people to engage in cycling for fitness and recreation.</p>
                            <p><b>7- Floral Gardens:</b> The park is adorned with well-maintained gardens and floral arrangements, providing a serene and aesthetically pleasing environment.</p>
                            <p><b>8- Statues and Monuments:</b> There are statues and monuments within the park, including a memorial dedicated to Janeshwar Mishra, commemorating his contributions to Indian politics.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Residency}
                onHide={() => setResidency(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Residency
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place3} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">The Residency is a historic complex located in Lucknow, Uttar Pradesh, India. It served as the official residence of the British Resident General who was a representative of the British East India Company during the period of British rule in India. The Residency is famous for its association with the events of the Indian Rebellion of 1857, also known as the Sepoy Mutiny or the First War of Indian Independence.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features and historical significance of the Residency:</p>

                            <p><b>1- Architectural Heritage:</b> The Residency complex includes a group of buildings, gardens, and other structures. The architecture is a blend of British and local styles, reflecting the colonial influence.</p>

                            <p><b>2- Indian Rebellion of 1857:</b> The Residency gained historical prominence during the Indian Rebellion of 1857. The complex became a focal point of the conflict, with the British residents and a small group of European and Indian soldiers taking refuge here during the siege of Lucknow.</p>

                            <p> <b>3- Siege of Lucknow:</b> The Residency endured a prolonged siege from May 30, 1857, to November 27, 1857. The British residents, along with their families and supporters, faced constant attacks from Indian rebels. The siege was a critical episode in the larger context of the 1857 uprising.</p>

                            <p><b>4- Ruins and Memorials:</b> Today, the Residency is mostly in ruins, with many buildings showing the scars of battle. The site has been preserved as a memorial to the soldiers and civilians who lost their lives during the siege. There are graves, memorials, and plaques dedicated to those who served and perished during the conflict.</p>

                            <p><b>5- Museum:</b> The Residency has a museum that houses artifacts, documents, and exhibits related to the events of 1857. It provides visitors with a detailed insight into the history and significance of the Residency during the rebellion.</p>

                            <p><b>6- Gardens:</b> The complex includes well-maintained gardens that add to the peaceful ambiance of the site. Visitors can explore the grounds and appreciate the historical and architectural elements.</p>


                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Rumygate}
                onHide={() => setRumygate(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Roomy Gate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place4} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Rumi Darwaza, also known as the Turkish Gate, is an iconic historical monument in Lucknow, Uttar Pradesh, India. It is not "Roomy Gate," and it seems there might have been a spelling error or a confusion in your previous queries.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Here are some key details about Rumi Darwaza:</p>

                            <p><b>1- Architectural Style:</b> Rumi Darwaza was constructed in 1784 by Nawab Asaf-ud-Daula. The structure is known for its impressive architectural style, which combines elements of Persian and Awadhi architecture.</p>

                            <p><b>2- Symbol of Lucknow:</b> Rumi Darwaza is often considered a symbol of Lucknow and is one of the most recognizable landmarks in the city. It stands as a testament to the rich cultural and historical heritage of Lucknow.</p>

                            <p> <b>3- Height:</b> The gate is around 60 feet tall and has a large arched entrance. It was modeled after the Sublime Porte (Bab-iHümayun) in Istanbul, Turkey.</p>

                            <p><b>4- Purpose:</b> Rumi Darwaza was originally built as an entrance to the city of Lucknow. It served both functional and symbolic purposes, reflecting the grandeur of the city during the Nawabi era.</p>

                            <p><b>5- Festivals:</b> The gate has been traditionally illuminated during various festivals, adding to its majestic appearance.</p>




                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Echogarden}
                onHide={() => setEchogarden(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Eco Garden
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place5} width="100%" className="mt-5" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-1">The Manyawar Shree Kanshiram Green Eco-Garden,which is set to come up at the site of Lucknows Old Jail abutting the Kanshiram Memorial in Alambagh,will lighten the state exchequer by a whopping Rs 424 crore.</p>
                            <p>The Uttar Pradesh Rajkiya Nirman Nigam (UPRNN),the executive agency of the project,has been directed to complete work on the garden by June.  The Lucknow Development Authority,the nodal agency for the project,had last week approved a tentative layout of the garden,following which the state government released Rs 199 crore to the UPRNN.</p>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>




            <Modal
                size="lg"
                show={Zoo}
                onHide={() => setZoo(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Lucknow Zoo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place6} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">The Lucknow Zoo, officially known as the Nawab Wajid Ali Shah Zoological Garden, is a popular zoological park located in Lucknow, Uttar Pradesh, India. It is one of the oldest zoos in the country and was established in 1921. The zoo is named after Nawab Wajid Ali Shah, the last Nawab of Awadh.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features and information about Lucknow Zoo:</p>

                            <p><b>1- Location:</b> The zoo is situated in the heart of Lucknow and is easily accessible. Its central location makes it a popular destination for locals and tourists.</p>

                            <p><b>2- Size and Layout:</b> The Lucknow Zoo covers a significant area and is home to a diverse range of animals. It is well laid out with different sections dedicated to various species.</p>

                            <p> <b>3- Animal Diversity:</b> The zoo houses a variety of animals, including mammals, birds, reptiles, and fish. Some of the animals you may find at the zoo include lions, tigers, elephants, giraffes, zebras, deer, crocodiles, snakes, and various species of birds.</p>

                            <p><b>4- Conservation and Education:</b> Apart from being a recreational destination, the zoo also plays a role in wildlife conservation and education. It aims to raise awareness about the importance of wildlife conservation and environmental protection.</p>

                            <p><b>5- Botanical Garden:</b> The zoo includes a botanical garden featuring a variety of plants, trees, and landscaping, providing a pleasant environment for visitors.</p>

                            <p><b>6- Animal Exhibits:</b> The zoo has well-designed enclosures for different animals, ensuring their well-being and allowing visitors to observe them in a more natural setting.</p>

                            <p><b>7- Visitor Facilities:</b> The Lucknow Zoo provides facilities for visitors, including seating areas, refreshment stalls, and other amenities to enhance the overall experience.</p>
                            <p><b>8- Conservation Initiatives:</b> The zoo is involved in various conservation initiatives, breeding programs, and research activities aimed at the welfare of the animals and contributing to biodiversity conservation.</p>




                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={Rama}
                onHide={() => setRama(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Ramabai Ambedkar Park
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place7} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">
                                Rani Laxmi Bai Park, also known as Ramabai Ambedkar Park, is a public park in Lucknow, Uttar Pradesh, India. The park is dedicated to Rani Laxmi Bai of Jhansi, a prominent figure in the Indian Rebellion of 1857, and Dr. B. R. Ambedkar, the principal architect of the Indian Constitution and a social reformer.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Here are some key features and information about Rani Laxmi Bai Park:</p>

                            <p><b>1- Location:</b> The park is located in the Gomti Nagar area of Lucknow and is easily accessible.</p>

                            <p><b>2- Statues and Memorials:</b> Rani Laxmi Bai Park features statues and memorials dedicated to Rani Laxmi Bai and Dr. B. R. Ambedkar. The statues pay tribute to their contributions to Indian history and society.</p>

                            <p> <b>3- Architecture:</b> The park has impressive architecture with well-designed pathways, landscaped gardens, and water features.</p>

                            <p><b>4- Ambedkar Memorial:</b> The park includes the Dr. B. R. Ambedkar Memorial, which commemorates the life and work of Dr. B. R. Ambedkar. The memorial showcases his ideals and contributions to social justice and equality.</p>

                            <p><b>5- Rani Laxmi Bai Memorial:</b> The park also pays homage to Rani Laxmi Bai, the warrior queen of Jhansi, who played a significant role in the Indian Rebellion of 1857 against British rule.</p>

                            <p><b>6- Cultural and Historical Significance:</b> Rani Laxmi Bai Park is not only a green space but also a place of cultural and historical significance. It serves as a reminder of the rich history and diverse contributions of these two iconic figures.</p>

                            <p><b>7- Visitors:</b> The park attracts visitors, tourists, and locals alike, providing a peaceful environment for relaxation and reflection.</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Gomti}
                onHide={() => setGomti(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Gomti River Front
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place8} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">
                                The Gomti Riverfront in Lucknow is a significant urban development project that focuses on the beautification and revitalization of the Gomti River and its surroundings. The project aims to transform the riverfront into an attractive and sustainable area for recreation, tourism, and environmental conservation.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features of the Gomti Riverfront in Lucknow:</p>

                            <p><b>1- Location:</b> The Gomti River runs through the city of Lucknow, and the riverfront development project spans various stretches along its course.</p>

                            <p><b>2- Beautification:</b> The project involves landscaping, gardens, and green spaces along the riverbanks to enhance the aesthetic appeal of the area.</p>

                            <p> <b>3- Promenades and Walkways:</b> The riverfront features well-designed promenades and walkways, providing residents and visitors with pleasant spaces for walking, jogging, and enjoying the scenic views of the river.</p>

                            <p><b>4- Recreational Facilities:</b> Parks, gardens, and recreational facilities have been developed along the riverfront to promote outdoor activities and community engagement.</p>

                            <p><b>5- Cultural Spaces:</b> Some sections of the Gomti Riverfront may include cultural spaces, amphitheaters, and open areas for hosting events, festivals, and cultural activities.</p>

                            <p><b>6- Bridges and Connectivity:</b> The project might include the construction or renovation of bridges, improving connectivity between different parts of the city.</p>

                            <p><b>7- Environmental Initiatives:</b> Efforts are often made to address environmental concerns, such as water quality improvement, waste management, and conservation of the river ecosystem.</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Lulu}
                onHide={() => setLulu(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        LULU Mall Lucknow
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place9} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">
                                LuLu Mall Lucknow is strategically located on NH 27, the Lucknow-Sultanpur National Highway which is a 126 kms long 4-lane highway on Amar Shaheed Path, with a dedicated service road for the Mall. The Mall is just 20 minutes from the city centre and the Airport. </p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">The Mall is located within Sushant Golf City with a total development of 1,85,800 sq. m. The mixed-use development of Sushant Golf City has luxury housing, educational institutes, clubs, hospitals and a magnificent 18 hole golf course spread across 6.8 lakh sq.m.</p>
                            <p className="mt-3">The Mall will house over 300 national and international brands and will feature a 6000 sq. m. Family Entertainment Centre as well as a 1600 seater Food Court, with a wide range of dining options. The parking facility of the Mall includes a highly sophisticated traffic management system and a Multi Level Car Park, which is directly connected to the Mall on all floors. Lulu Mall Lucknow will be a destination Mall for the people of Lucknow and neighbouring cities.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>



            <div className="w3l-homeblog" id="">
                <div className="container py-lg-5 py-md-4">
                    <div className="header-title">
                        <span className="sub-title">EXPLORE CUISINES OF LUCKNOW</span>

                    </div>
                    <div className="row top-pics ">

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Cuisine1} alt="" className="img-fluid" />
                            <p className="title">TUNDAY KABABI</p>
                            <div className="overlay"></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Cuisine2} alt="" className="img-fluid" />
                            <p className="title">MUGHLAI FOOD</p>
                            <div className="overlay"></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Cuisine3} alt="" className="img-fluid" />
                            <p className="title">AWADHI CUISINE</p>
                            <div className="overlay"></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Cuisine4} alt="" className="img-fluid" />
                            <p className="title">KULCHA NAHARI</p>
                            <div className="overlay"></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Cuisine5} alt="" className="img-fluid" />
                            <p className="title">LUCKNOW BASKET CHAAT</p>
                            <div className="overlay"></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Cuisine6} alt="" className="img-fluid" />
                            <p className="title">SHARMA JI KI CHAI</p>
                            <div className="overlay"></div>
                        </div>



                    </div>
                </div>
            </div>








        </>
    );


}
export default Cuisines;