import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import Dropzone from "react-dropzone";
import Constants from "../utils/Constants";
import Loader from "../utils/Loader";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QR from '../images/qrcode.png';
import Radio from '@mui/material/Radio';
import { useParams } from "react-router-dom";
import { NavLink, Link, useNavigate } from "react-router-dom";
export const Thanks = () => {
    const { id } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><a href="/">HOME</a></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> THANK YOU</li>
                        </ul>
                    </div>
                </section>
            </div>

            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="row">

                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">THANK YOU</span>

                                </div>

                                <div className="col-md-12 text-left">
                                    <p className="thanksmessage">Thanks for the registration in National PG Convention. Your Registration ID is <b>{id}</b>. </p>
                                    <p className="thanksmessage">Please store this Registration ID for future prospects.</p>
                                </div>

                                <div className="col-md-12 text-center">
                                    <NavLink to="/" className="btn btn-success"> Go back To Home </NavLink>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );

}
export default Thanks;