import React, { Component } from "react";
import Cuisines from "../Component/Cuisines";
class FamousCuisines extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> FAMOUS CUISINES</li>
                            </ul>
                        </div>
                    </section>
                </div>

                <Cuisines />
            </>
        );
    }
}
export default FamousCuisines;