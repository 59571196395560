import React, { Component, useState } from "react";
import Dropzone from "react-dropzone";
import Loader from "../utils/Loader";
import Swal from "sweetalert2";
import axios from "axios";
import Constants from "../utils/Constants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const SubmitAbstract = () => {
    const navigate = useNavigate()
    const [showLoading, setShowLoading] = useState(false);
    const [abstract, setAbstract] = useState('');
    const [registrationno, setRegistrationno] = useState('');
    const [category, setCategory] = useState('');
    const [name, setName] = useState('');
    const [collegename, setCollegename] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [faculty, setFaculty] = useState('');
    const [fileName, setFilename] = useState('');
    function getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }
    const handleDropProof = (acceptedFiles) => {
        const extension = getFileExtension(acceptedFiles[0]['name']);
        if (extension == 'pdf' || extension=='doc' || extension=='docx') {
            setAbstract(acceptedFiles[0]);
            setFilename("File Name: " + acceptedFiles[0]['name']);
        }
        else {
            Swal.fire({
                icon: "Warning",
                title: "Oops...",
                text: "Only PDF and Word Files Accepted.",
                footer: ''
            });
            setAbstract('');
        }

    };

    const handleUpload = (e) => {
        e.preventDefault();
        setShowLoading(true);
        if (abstract === '' && type != 'Quiz') {
            setShowLoading(false);
            Swal.fire("Please upload abstract file.");

        }
        let payload = {
            registrationno: registrationno,
            faculty: faculty,
            name: name,
            collegename: collegename,
            type: type,
            category: category,
            abstract: abstract,
            title: title,
        }

        axios({
            method: "POST",
            url: Constants.baseUrl + "submit-abstract",
            data: payload,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {


                setShowLoading(false);
                Swal.fire({
                    icon: "Success",
                    title: "Success",
                    text: "You have successfully submitted the abstract.",
                    footer: ''
                });

                setAbstract('');
                setRegistrationno('');
                setCategory('');
                setName('');
                setCollegename('');
                setTitle('');
                setType('');
                setFaculty('');

            })
            .then((data) => {
                console.log(data)
            })
            .catch(error => {
                setShowLoading(false);
                Swal.fire({
                    icon: "Warning",
                    title: "Oops...",
                    text: "Please Input all required fields",
                    footer: 'Please Check All Fields'
                });
            });
    }

    const handleChange = (event) => {
        setType(event.target.value);
    };


    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><Link to="/">HOME</Link></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> FORM FOR PAPER/ POSTER/ QUIZ</li>
                        </ul>
                    </div>
                </section>
            </div>
            {
                showLoading ?

                    <Loader />
                    :

                    ''
            }
            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="row">
                            <div className="col-lg-12 themeheading">
                                <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                            </div>
                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">Form For Paper/ Poster/ Quiz</span>

                                </div>

                                <div className="col-md-12 text-left mt-3">
                                     <h3 align="center">Last date of submission has been over.</h3>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default SubmitAbstract;