import React from 'react'

export const NationalCoordinators = () => {
    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><a href="/">HOME</a></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> NATIONAL COORDINATORS</li>
                        </ul>
                    </div>
                </section>
            </div>
            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="row">
                            <div className="col-lg-12 themeheading">
                                <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                            </div>
                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">National Coordinators</span>

                                </div>

                                <div className="col-md-12 text-left">
                                    <table className="table">
                                        <tr>
                                            <td>Dr. Abhilash</td>
                                            <td>Dr. Abhishek</td>
                                            <td>Dr. Adit Srivastava</td>
                                            <td>Dr. Aditya Dupare</td>
                                        </tr>

                                        <tr>
                                            <td>Dr. Agnihotri </td>
                                            <td>Dr. Aishwarya Sharma</td>
                                            <td>Dr. Ajay Parihar</td>
                                            <td>Dr. Ajmal</td>
                                        </tr>

                                        <tr>
                                            <td>Dr. Altaf Hussain Chalkoo</td>
                                            <td>Dr. Ambika Gupta</td>
                                            <td>Dr. Amit Agarwal</td>
                                            <td>Dr. Amit Kumar Singh</td>
                                        </tr>

                                        <tr>
                                            <td>Dr. Amit Mhapuskar </td>
                                            <td>Dr. Amita Navalkar</td>
                                            <td>Dr. Amol Dhokar</td>
                                            <td>Dr. Anabrasi K.</td>
                                        </tr>

                                        <tr>
                                            <td>Dr. Anil Ghom </td>
                                            <td>Dr. Anil Kumar.N</td>
                                            <td>Dr. Anis Ahmed </td>
                                            <td>Dr. Anita Balan</td>
                                        </tr>

                                        <tr>
                                            <td>Dr. Anita D Munde</td>
                                            <td>Dr. Anjana Bagewadi</td>
                                            <td>Dr. Ankita Srivastava</td>
                                            <td>Dr. Ankur Singh</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Anu Vijayan</td>
                                            <td>Dr. Anuradha Ganesan</td>
                                            <td>Dr. Anuradha Pai</td>
                                            <td>Dr. Anushman Jamdhane </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Arati Chaudhary</td>
                                            <td>Dr. Aravind Shetty</td>
                                            <td>Dr. Aravind Warrier </td>
                                            <td>Dr. Archana M. S. </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Archana Sudhir</td>
                                            <td>Dr. Aruna</td>
                                            <td>Dr. Asha M L</td>
                                            <td>Dr. Asha Nagaraj </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Asha R lyengar</td>
                                            <td>Dr. Ashish Aggarwal</td>
                                            <td>Dr. Ashish Lanjekar </td>
                                            <td>Dr. Ashok L</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Ashwarya Trivedi </td>
                                            <td>Dr. Atul P Sattur</td>
                                            <td>Dr. Avinash Kshar</td>
                                            <td>Dr. B Seshi Rekha</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. B Siva</td>
                                            <td>Dr. Babu Mathew </td>
                                            <td>Dr. Badri Ramakrishna Botu</td>
                                            <td>Dr. Balaji</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Balan</td>
                                            <td>Dr. Basavaraj T Bhagavathi</td>
                                            <td>Dr. Beena Kumary T.P</td>
                                            <td>Dr. Bhadage Chetan J</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Bharath Modi</td>
                                            <td>Dr. Bhavana Barthunia </td>
                                            <td>Dr. Bhoosreddy Ajay R</td>
                                            <td>Dr. Bilhari</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Birangane</td>
                                            <td>Dr. C Pravadha</td>
                                            <td>Dr. C Saravanan Bharathi</td>
                                            <td>Dr. C.B. More</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Capt Vivek V</td>
                                            <td>Dr. Capt. Elangovan</td>
                                            <td>Dr. Chandani Shekhwat</td>
                                            <td>Dr. Chandra Mouli</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Chandramani B. More</td>
                                            <td>Dr. Charu</td>
                                            <td>Dr. Chaya M David</td>
                                            <td>Dr. Chhaya Adalja</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Christeffi Mabel</td>
                                            <td>Dr. D Angelin Deepthi</td>
                                            <td>Dr. D.N.S.V Ramesh</td>
                                            <td>Dr. Daman Preeth Kaur</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Darshan Rajendra Hiremutt</td>
                                            <td>Dr. Daya K Jangam</td>
                                            <td>Dr. Debanti Giri</td>
                                            <td>Dr. Deepa</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Deepa A Das</td>
                                            <td>Dr. Deepak Daryani</td>
                                            <td>Dr. Deepak Gupta</td>
                                            <td>Dr. Deepak T A</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Deepankar Mishra</td>
                                            <td>Dr. Dhaval N. Mehta</td>
                                            <td>Dr. Dipti Bhatnagar</td>
                                            <td>Dr. Easwaran Ramaswami</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Fakir Mohan Debta</td>
                                            <td>Dr. Freny Karjodkar</td>
                                            <td>Dr. G Murali K. Manoharan</td>
                                            <td>Dr. G N Suma</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. G S Asokan</td>
                                            <td>Dr. G V Rama Chandra Reddy</td>
                                            <td>Dr. G. Sabitha</td>
                                            <td>Dr. G. Subash</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Gaurav</td>
                                            <td>Dr. Geetha Kamath</td>
                                            <td>Dr. Giju George</td>
                                            <td>Dr. Giridhar S. Naidu</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Girija K L</td>
                                            <td>Dr. Girish Katti</td>
                                            <td>Dr. Guruprasad </td>
                                            <td>Dr. Harshvardhan</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. HR Umarji</td>
                                            <td>Dr. J. Venkatesh</td>
                                            <td>Dr. Jagat Reddy RC</td>
                                            <td>Dr. Jaju Prashant Prakash</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Jayanthi </td>
                                            <td>Dr. Jayaprasad Anekar </td>
                                            <td>Dr. Jeevitha. M</td>
                                            <td>Dr. Jigna S. Shah</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Jincy Thomas </td>
                                            <td>Dr. Jonathan Daniel </td>
                                            <td>Dr. Junaid Ahmed</td>
                                            <td>Dr. Jyothi</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Jyothi S. Kumar</td>
                                            <td>Dr. K Nagaraju</td>
                                            <td>Dr. K S Ganapathy</td>
                                            <td>Dr. K Srinivas</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. K.S. Nagesh</td>
                                            <td>Dr. Kamala</td>
                                            <td>Dr. Kamala K A</td>
                                            <td>Dr. Kaustub Sansare</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Keerthi Latha Pai</td>
                                            <td>Dr. Kirty R Nandimath</td>
                                            <td>Dr. Kodanda Rama</td>
                                            <td>Dr. Koijam Sashi Kumar</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Koteeshwaran</td>
                                            <td>Dr. Krishna Burude </td>
                                            <td>Dr. L. S. Sreela</td>
                                            <td>Dr. Laxmikanth Chatra</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Lina Govind Chandak </td>
                                            <td>Dr. M Aravind</td>
                                            <td>Dr. M Deivanayagi</td>
                                            <td>Dr. M Subha</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. M Sudha Mani</td>
                                            <td>Dr. M. K. Sunil</td>
                                            <td>Dr. Mahalakshmi L Lature</td>
                                            <td>Dr. Mahendra Patait</td>
                                        </tr>

                                        <tr>
                                            <td>Dr. Mahendra Patil</td>
                                            <td>Dr. Mahima Patil</td>
                                            <td>Dr. Mamta Sharma </td>
                                            <td>Dr. Mandavi S. Waghmare</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Mangala Metti </td>
                                            <td>Dr. Manisha Khorate </td>
                                            <td>Dr. Manisha Lakhanpal</td>
                                            <td>Dr. Manjunath</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Manoj</td>
                                            <td>Dr. Manoj Vengal</td>
                                            <td>Dr. Manu Dhillon</td>
                                            <td>Dr. Maraghathavalli</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Maria Priscilla David</td>
                                            <td>Dr. Mathew</td>
                                            <td>Dr. Megha Goyal</td>
                                            <td>Dr. Mohan C.V</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Mohit Pal Singh</td>
                                            <td>Dr. Mufeed</td>
                                            <td>Dr. Mukesh Asrani</td>
                                            <td>Dr. Mukta Mothwani</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Murali Gopika Monaharan</td>
                                            <td>Dr. N Balan</td>
                                            <td>Dr. N Santana</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Dr. N. R. Diwakar</td>
                                            <td>Dr. Nagaraju Rakesh</td>
                                            <td>Dr. Nalini Aswath </td>
                                            <td>Dr. Navneet Gill </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Neelkanth Patil </td>
                                            <td>Dr. Neeta Sharma</td>
                                            <td>Dr. Neha Agarwal</td>
                                            <td>Dr. Nileena R. Kumar</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Nilesh Patel</td>
                                            <td>Dr. Nimesh Kumar Patel</td>
                                            <td>Dr. Nimmi Singh</td>
                                            <td>Dr. Nisha Dua</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Nisheet S</td>
                                            <td>Dr. P. T. Ravi Kumar</td>
                                            <td>Dr. P. V. Wanjari</td>
                                            <td>Dr. Payal Tripathi</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Pinaka Pani</td>
                                            <td>Dr. Poornima C.</td>
                                            <td>Dr. Pradhuman Verma</td>
                                            <td>Dr. Pragyan Das</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Pramod John</td>
                                            <td>Dr. Prasanna</td>
                                            <td>Dr. Prashant Gupta</td>
                                            <td>Dr. Prashant Jaju</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Prashanth Nahar</td>
                                            <td>Dr. Prashanth Patil</td>
                                            <td>Dr. Prashanthi Reddy</td>
                                            <td>Dr. Prathima Sreenivasan</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Praveena</td>
                                            <td>Dr. Praveena Tantrado</td>
                                            <td>Dr. Preethi Arora </td>
                                            <td>Dr. Preeti Nair</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Prerna Taneja</td>
                                            <td>Dr. Prescilla David</td>
                                            <td>Dr. Pritesh Ruparelia </td>
                                            <td>Dr. Priya Ramani </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Puneeta Vohra</td>
                                            <td>Dr. R. R. Mahendra Raj</td>
                                            <td>Dr. Raghavendra </td>
                                            <td>Dr. Raghavendra Kini </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Raghunandan Sindhe </td>
                                            <td>Dr. Raj AC </td>
                                            <td>Dr. Rajan</td>
                                            <td>Dr. Rajeev Sharma </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Rajendra Gouda Patil</td>
                                            <td>Dr. Rajesh Gupta </td>
                                            <td>Dr. Rajeswari Annegar</td>
                                            <td>Dr. Ramamurthy TK</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Ramandeep Singh Punia</td>
                                            <td>Dr. Ramasamy</td>
                                            <td>Dr. Ramesh Gupta</td>
                                            <td>Dr. Ramesh T</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Ramnarayan BK</td>
                                            <td>Dr. Ranjit Kumar Patil</td>
                                            <td>Dr. Ranjitha Mehta</td>
                                            <td>Dr. Rashmi Venkatesh</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Ravi David Austin</td>
                                            <td>Dr. Ravi Kiran Ongole</td>
                                            <td>Dr. Ravinder Singh Sohi</td>
                                            <td>Dr. Renju Jose </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Renuka</td>
                                            <td>Dr. Revant Harish Chandra Chole</td>
                                            <td>Dr. Rohit Agarwal </td>
                                            <td>Dr. Rohit Malik</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Rohith Sharma</td>
                                            <td>Dr. Roop Sirkar </td>
                                            <td>Dr. Roopashree G. </td>
                                            <td>Dr. Roopika Handa</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Rupam Sinha</td>
                                            <td>Dr. S. Fathima Khan</td>
                                            <td>Dr. S. Jaya Chandran </td>
                                            <td>Dr. Saba Khan</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Sanat Kumar Bhuyan</td>
                                            <td>Dr. Sandeep Shreekar Pagare</td>
                                            <td>Dr. Sangamesh NC</td>
                                            <td>Dr. Sanjay Nyamati</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Sapna Raut Dessai</td>
                                            <td>Dr. Sarbanideb Sikdar</td>
                                            <td>Dr. Sarswathi Gopal</td>
                                            <td>Dr. Sasi Kumar</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Satasiva Subramanyam</td>
                                            <td>Dr. Sathavane</td>
                                            <td>Dr. Satish Kumar </td>
                                            <td>Dr. Satya Ranjan Misra</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Seema Ashwin Bhogte</td>
                                            <td>Dr. Seema Patil </td>
                                            <td>Dr. Selva MuthuKumar</td>
                                            <td>Dr. Shailaja Sankir Reddy</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Shalini Gupta</td>
                                            <td>Dr. Shanmugum</td>
                                            <td>Dr. Shilpa</td>
                                            <td>Dr. Shilpa J. Parikh</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Shilpa Patil</td>
                                            <td>Dr. Shilpa PS</td>
                                            <td>Dr. Shivakumar GC</td>
                                            <td>Dr. Shivanand Bagewadi</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Shivaprasad S </td>
                                            <td>Dr. Shobha</td>
                                            <td>Dr. Shubhasini AR</td>
                                            <td>Dr. Shwetha Hegde</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Simi Thankappan</td>
                                            <td>Dr. Sivabalakrishna</td>
                                            <td>Dr. Siddharth Kumar Singh</td>
                                            <td>Dr. Sonali Kadam</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Sonalika Srivastava</td>
                                            <td>Dr. Sounali Khanna</td>
                                            <td>Dr. Sowmya Krishna</td>
                                            <td>Dr. Sreedevi</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Srihari T.G</td>
                                            <td>Dr. Sri Krishna K</td>
                                            <td>Dr. Srinivasan</td>
                                            <td>Dr. Sudhir Shukla</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Suhas Shetty</td>
                                            <td>Dr. Sujatha</td>
                                            <td>Dr. Sulabha</td>
                                            <td>Dr. Suma M S</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Suman Jai Shankar</td>
                                            <td>Dr. Sumit Bhateja</td>
                                            <td>Dr. Sumit Dutta</td>
                                            <td>Dr. Sunaira Chandra</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Sundar Raj</td>
                                            <td>Dr. Suneetha</td>
                                            <td>Dr. Sunil</td>
                                            <td>Dr. Sunil R. Panat</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Sunila Thomas</td>
                                            <td>Dr. Surekha</td>
                                            <td>Dr. Suresh K. Sachadeva </td>
                                            <td>Dr. Sushanth</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Syeda Arshiyaara</td>
                                            <td>Dr. T. Manikandan</td>
                                            <td>Dr. T. N. Uma Maheswari</td>
                                            <td>Dr. T. Saravanan</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Tarun Vyas</td>
                                            <td>Dr. Tatu Joy</td>
                                            <td>Dr. Tejavathi Nagaraj </td>
                                            <td>Dr. Thriveni.R </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Tinky Bose</td>
                                            <td>Dr. Tushar Phulambrika </td>
                                            <td>Dr. Twinkle S. Prasad</td>
                                            <td>Dr. Uday Reddy</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Upasana Sethi </td>
                                            <td>Dr. Usha </td>
                                            <td>Dr. V. Sreenivasan</td>
                                            <td>Dr. Vaishali Keluskar</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Vandana</td>
                                            <td>Dr. Vathsala Naik</td>
                                            <td>Dr. Veena Patel</td>
                                            <td>Dr. Veena S.N</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Vela. D. Desai </td>
                                            <td>Dr. Venkatesh Naik Masur </td>
                                            <td>Dr. Vennila </td>
                                            <td>Dr. Vibha Jain</td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Vidya Ajila </td>
                                            <td>Dr. Vidya K Lohe </td>
                                            <td>Dr. Vijaya Lakshmi</td>
                                            <td>Dr. VijayaRagavan </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Vijeev Vasudevan </td>
                                            <td>Dr. Vinay Mohan </td>
                                            <td>Dr. Vinay Reddy</td>
                                            <td>Dr. Vineetha R </td>
                                        </tr>
                                        <tr>
                                            <td>Dr. Vinod VC</td>
                                            <td>Dr. Vishal Dang </td>
                                            <td>Dr. Vishal Mehrotra </td>
                                            <td>Dr. Vivek</td>
                                        </tr>

                                    </table>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
