import React, { useState, useEffect } from 'react';

function CountdownTimer({ targetDate }) {
  // State to store the remaining time
  const [timeLeft, setTimeLeft] = useState('');

  // Update the countdown every second
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

     
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

     
      setTimeLeft(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);

      
      if (difference < 0) {
        clearInterval(interval);
        setTimeLeft('Time is up!');
      }
    }, 1000);

     
    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="timerdiv">
    {timeLeft}
    </div>
  );
}

export default CountdownTimer;

// Usage example
// <CountdownTimer targetDate={new Date('2024-05-20T15:30:00')} />
