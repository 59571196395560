import React, { Component } from "react";
class Hotels extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> LIST OF HOTELS</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container py-lg-5 py-md-3">
                            <div className="row">

                                <div className="col-lg-12 pl-lg-5 mb-5">
                                    <div className="header-title">
                                        <span className="sub-title">LIST OF HOTELS</span>

                                    </div>

                                    <div className="col-md-12 text-left">
                                        <p className="mt-4 mb-4">The IAOMR NATIONAL PG CONVENTION
                                            has EXCLUSIVELY PARTNERED with the following
                                            HOTELS to offer discounted rates for attendees.</p>
                                        <table className="table">
                                            <tr>
                                                <td colSpan={3} align="center">
                                                    <center>
                                                        <p> <b>HOLIDAY INN, LUCKNOW</b></p>
                                                        <p>  Distance from Venue- 7.4 Km</p>
                                                        <p>  Estimated Travel Time- 10 min.</p>
                                                    </center>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Standard (Per Person on
                                                    Double Sharing basis)</td>
                                                <td>Rs. 2500</td>
                                                <td>12% Tax</td>
                                            </tr>

                                            <tr>
                                                <td>Standard (Per Person on
                                                    Triple Sharing basis)</td>
                                                <td>Rs. 2350</td>
                                                <td>12% Tax</td>
                                            </tr>



                                        </table>

                                        <table className="table">
                                            <tr>
                                                <td colSpan={3} align="center">
                                                    <center>
                                                        <p><b> CLICK ON TRANSPORT NAGAR, LUCKNOW</b></p>
                                                        <p> Distance from Venue- 6.2 Km</p>
                                                        <p> Estimated Travel Time- 10 min.</p>
                                                    </center>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Standard (Per Person on
                                                    Double Sharing basis)</td>
                                                <td>Rs. 1350</td>
                                                <td>12% Tax</td>
                                            </tr>

                                            <tr>
                                                <td>Standard (Per Person on
                                                    Triple Sharing basis)</td>
                                                <td>Rs. 1350</td>
                                                <td>12% Tax</td>
                                            </tr>



                                        </table>

                                        <p><b>RATE INCLUDES:</b></p>
                                        <ol>
                                            <li>Rates are based on per Person Per Night basis</li>
                                            <li>Accommodation on Double/Triple sharing basis</li>
                                            <li>All Taxes (As mentioned)</li>
                                        </ol>
                                        <p><b>CONTACT:</b></p>
                                        <p>Guest Service Contact: 1800 123 320000 OR +91-7392977768 / 9598989811</p>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default Hotels;