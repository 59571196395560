import React, { Component, useState } from "react";
import Lightbox from "../Component/Lightbox";
import GalleryModal from "../Component/GalleryModal";
import P1 from "../pre/P1.jpg";
import P2 from "../pre/P2.jpg";
import P3 from "../pre/P3.jpg";
import P4 from "../pre/P4.jpg";
import P5 from "../pre/P5.jpg";
const PreConvention = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgsrc, setImgSrc] = useState(false);
    const handleClick=(param)=> () => {

        setImgSrc(param);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
      };
        return (
            <><GalleryModal isOpen={isModalOpen} close={closeModal} imgsrc={imgsrc} />
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> PRE-CONVENTION COURSE</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container-fluid py-lg-5 py-md-3">
                            <div className="row">
                                <div className="col-lg-12 themeheading">
                                    <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                                </div>
                                <div className="col-lg-12 pl-lg-5 mb-5">
                                    <div className="header-title">
                                        <span className="sub-title">PRE-CONVENTION COURSE</span>

                                    </div>
                                    <div className="row">
                                    <div className="col-md-4 gallery">
                                    <img
        src={P5}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(P5)}
      />
      
                                    </div>
                                    <div className="col-md-4 gallery">
                                    <img
        src={P1}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(P1)}
      />
      
                                    </div>


                                    <div className="col-md-4 gallery">
                                    <img
        src={P2}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(P2)}
      />
     
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={P3}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(P3)}
      />
      
                                    </div>

                                    <div className="col-md-4 gallery">
                                    <img
        src={P4}
        alt="Cute kitten"
        style={{ maxWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick(P4)}
      />
      
                                    </div>

                                   

                                   

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

export default PreConvention;