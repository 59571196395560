import React,{useState } from "react";
import { NavLink } from "react-router-dom";
import Top from '../images/top.jpg';

const Modals = ({ isOpen, close }) => {
  const [expanded, setExpanded] = useState(false);
  const handleNavbarItemClick = () => {
    // Hide the navbar after clicking an item
    setExpanded(false);
};
  if (!isOpen) return null;

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}>
      <div style={{
        padding: 20,
        background: "#fff",
        borderRadius: "5px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        minWidth: "300px",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}>
        <p>Welcome to IAOMR PG CONVENTION 2024</p>
        
        <p>Dear Postgraduates - <b>"Shape your thoughts, shape the future: participate in Debate!"</b></p>

        <p><b>Winner will get Cash Prize 💰🤑🤑</b></p>
        {/*<p><NavLink to="/speakers" className="btn btn-primary" onClick={handleNavbarItemClick} >Guest Lectures</NavLink></p>
        <p><NavLink to="/preconvention-course" className="btn btn-warning" onClick={handleNavbarItemClick} >Pre-Convention Courses</NavLink></p>*/}

        <p>Debate Topic:<b> "The Impact of Diversification on Oral Medicine and Radiology: Enhancing or Hindering Teaching and Practice?"</b></p>

        <p><NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSezS3QfU5wUFr_9ipVrJRAYuebWjZl7neQVLmfQBVHthGIDhw/viewform?pli=1" target="_blank" className="btn btn-primary" >Register Now</NavLink></p>
        <button onClick={close} className="btn btn-danger">Close</button>
      </div>
    </div>
  );
};

export default Modals;
