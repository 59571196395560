import React, { Component } from "react";
import QR from '../images/qrcode.png';
import { NavLink, Link, useNavigate } from "react-router-dom";
class RegistrationDetails extends Component {
  render() {
    return (
      <>
        <div className="inner-banner">
          <section className="w3l-breadcrumb text-left">
            <div className="container">
              <ul className="breadcrumbs-custom-path">
                <li><a href="index.html">HOME</a></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span>REGISTRATION DETAILS</li>
              </ul>
            </div>
          </section>
        </div>
        <section className="w3l-index3" id="about1">
          <div className="midd-w3 py-5">
            <div className="container py-lg-5 py-md-3">
              <div className="row">
                <div className="col-lg-12 themeheading">
                  <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                </div>
                <div className="col-lg-12 pl-lg-5 mb-5">
                  <div className="header-title">
                    <span className="sub-title">REGISTRATION DETAILS</span>

                  </div>

                  <p className="text-left"><b>Note:</b></p>
                  <ol className="text-left notes">
                    <li>Registration is mandatory for all delegates.</li>
                    <li>Conference registration includes - registration kit, gift and conference attendence certificate.</li>
                    <li>Registration amount includes two breakfasts, two lunches and a banquet.</li>
                    <li>Registration fee does not includes accomodation and transportaion.</li>
                    <li>Registration fee is non refundable and non transferable.</li>
                    <li>Delegates registering via spot registration are not eligible for gift and scientific poster and paper presentations.</li>
                    <li>Documents required for filling the registration form:
                      <ul>
                        <li>Soft copy of the passport size photograph (jpeg, jpg)</li>
                        <li>Transaction ID/ Transaction details screenshot.</li>
                      </ul>
                    </li>
                  </ol>
                  <table className="table table-striped mt-5">
                    <tr>
                      <td width="" valign="middle"><p>&nbsp;</p></td>
                     
                      <td width="" valign="middle"> </td>
                      <td width="" valign="middle"><p align="center"><strong> 15<sup>th</sup> July    2024 Onwards</strong></p></td>
                    </tr>
                    <tr>
                      <td width="" valign="middle"><p>Student Delegate<br />
                      </p></td>
                     
                      <td width="" valign="middle"> </td>
                      <td width="" valign="middle"><p align="center"><strong>&#8377; 8850 </strong> </p></td>
                    </tr>

                    <tr>
                      <td width="" valign="middle"><p>Faculty Delegate<br />
                        <strong></strong></p></td>
                      
                      <td width="" valign="middle"> </td>
                      <td width="" valign="middle"><p align="center"><strong>&#8377; 10030 </strong></p></td>
                    </tr>

                    <tr>
                      <td width="" valign="middle"><p>Accompanying Person</p></td>
                      
                      <td width="" valign="middle"></td>
                      <td width="" valign="middle"><p align="center"><strong>&#8377; 5900 </strong></p></td>
                    </tr>
                    <tr>
                      <td width="" valign="middle"><p>Foreign Delegate</p></td>
                     
                      <td width="" valign="middle"></td>
                      <td width="" valign="middle"><p align="center"><strong>$ 250</strong></p></td>
                    </tr>
                  </table>


                  <p><b>Note: </b>The registration amount is inclusive of 18% gst</p>

                </div>

                <div className="col-lg-12 pl-lg-5 mb-5">
                  <div className="header-title">
                    <span className="sub-title">IAOMR NATIONAL PG CONVENTION 2024 BANK DETAILS
                    </span>

                  </div>
                </div>
                <div className="col-lg-6 pl-lg-5 mb-5">
                  <table className="table">
                    <tr>
                      <td><b>Beneficiary Name:</b></td>
                      <td>IAOMR NATIONAL PG CONVENTION 2024</td>
                    </tr>

                    <tr>
                      <td><b>Bank Account Number:</b></td>
                      <td>42516036224</td>
                    </tr>

                    <tr>
                      <td><b>Name of the Bank:</b></td>
                      <td>STATE BANK OF INDIA</td>
                    </tr>

                    <tr>
                      <td><b>Branch Name:</b></td>
                      <td>UTRETHIA</td>
                    </tr>

                    <tr>
                      <td><b>Account type:</b></td>
                      <td>CURRENT ACCOUNT</td>
                    </tr>

                    <tr>
                      <td><b>IFSC code:</b></td>
                      <td>SBIN0016727</td>
                    </tr>
                  </table>
                  <p className="text-left"><b>Note:</b></p>
                  <ol className="text-left notes">
                    <li>Payment should be completed before filling the registration form.</li>
                    <li>Payment may be done via UPI (BHIM, Phone Pe, Google Pay), NEFT and Internet Banking only.</li>


                  </ol>
                  <p className="text-left">For More information feel free to call on these numbers:</p>
                  <p className="text-left"><b>Dr Abhishek Sinha (Organizing Chairman) :</b>    <a className="text-ydellow" href="tel:+91-8948116600">+91-8948116600</a><br />
                    <b>Dr Sunita Srivastava (Organizing Secretary) :</b>
                    <a href="tel:+91-7607722454" className="text-yelslow">+91-7607722454</a><br />
                    <b>Dr Anuj Mishra (Conference Treasurer):</b><a href="tel:+91-8127005593" className="text-ydellow">+91-8127005593</a></p>
                  <NavLink to="/online-registration" className="btn btn-success btn-white mt-sm-5 mt-4 mr-2"> Please Proceed for Registration</NavLink>
                </div>
                <div className="col-lg-6 pl-lg-5 mb-5">
                  <img src={QR} width="100%" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default RegistrationDetails;