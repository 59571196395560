import Carousel from 'react-bootstrap/Carousel';
import BannerOne from '../images/banner1.jpg';
import BannerTwo from '../images/banner2.jpg';
import BannerThree from '../images/banner3.jpg';
import BannerFour from '../images/banner4.gif';
import BannerFive from '../images/banner5.jpeg';

function Slider() {
  return (
    <Carousel>
      <Carousel.Item>
        <img src={BannerFour} width="100%" />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={BannerTwo} width="100%" />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={BannerThree} width="100%" />
        <Carousel.Caption>
          <h3 className='venuename'>Conference Venue</h3>
          <h1 className='bannerheading'>Sardar Patel Post Graduate Institute</h1>

          <h1 className='bannerheading'> of Dental & Medical Sciences</h1>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={BannerFive} width="100%" />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>


    </Carousel >
  );
}

export default Slider;