import React, { Component } from "react";
import Snehlata from '../images/Snehlata.jpeg'
import Anurag from '../images/Anurag.jpeg'
import Shlok from '../images/Shlok.jpeg'
import Arohan from '../images/Arohan.jpeg'
import Gaurav from '../images/Gaurav.jpeg'
import Cpverma from '../images/Cpverma.jpeg'
import Hanuman from '../images/Hanuman.jpeg'
import M57 from '../images/m57.jpeg';
import M58 from '../images/m58.jpeg';
class AdvisoryBoard extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> PATRONS</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container py-lg-5 py-md-3">
                            <div className="row">

                                <div className="col-lg-12 pl-lg-5 mb-5">

                                    <div className="col-lg-12 themeheading">
                                        <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                                    </div>
                                    <div className="col-lg-12 pl-lg-5 mb-5">
                                        <div className="header-title">
                                            <span className="sub-title">CHIEF PATRONS</span>

                                        </div>
                                        <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                                            <div className="row">
                                                <div className="col-lg-3 mb-5 mx-auto">
                                                    <img src={M57} width="100%" className="doctor" />
                                                    <h3 className="comitteenames">Mr. Anurag Singh</h3>
                                                    <p className="comitteeposts">CHAIRMAN - SPPGIDMS</p>
                                                </div>
                                                <div className="col-lg-3  mb-5 mx-auto">
                                                    <img src={M58} width="100%" className="doctor" />
                                                    <h3 className="comitteenames">Dr. Snehlata Singh</h3>
                                                    <p className="comitteeposts">SECRETARY - SPPGIDMS</p>
                                                </div>



                                            </div>
                                        </div>

                                        <div className="header-title">
                                            <span className="sub-title">PATRONS</span>

                                        </div>
                                        <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                                            <div className="row">
                                                <div className="col-lg-3 mb-5 mx-auto">
                                                    <img src={Gaurav} width="100%" className="doctor" />
                                                    <h3 className="comitteenames">Dr. Gaurav Singh</h3>
                                                    <p className="comitteeposts">PRINCIPAL - SPPGIDMS</p>
                                                </div>

                                                <div className="col-lg-3 mb-5 mx-auto">
                                                    <img src={Arohan} width="100%" className="doctor" />
                                                    <h3 className="comitteenames">Dr. Arohan Singh</h3>
                                                    <p className="comitteeposts">MANAGING DIRECTOR - SPPGIDMS</p>
                                                </div>



                                            </div>
                                        </div>
                                        <div className="header-title">
                                            <span className="sub-title">ADVISORS</span>

                                        </div>
                                        <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                                            <div className="row">
                                                <div className="col-lg-3 mb-5 mx-auto">
                                                    <img src={Cpverma} width="100%" className="doctor" />
                                                    <h3 className="comitteenames">Mr. CP Verma</h3>
                                                    <p className="comitteeposts">CEO - SPPGIDMS</p>
                                                </div>

                                                <div className="col-lg-3 mb-5 mx-auto">
                                                    <img src={Hanuman} width="100%" className="doctor" />
                                                    <h3 className="comitteenames">Dr. Hanuman Singh</h3>
                                                    <p className="comitteeposts">ADVISOR - SPPGIDMS</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default AdvisoryBoard;