import React, { Component } from "react";
class ScientificGuidelines extends Component {
  render() {
    return (
      <>
        <div className="inner-banner">
          <section className="w3l-breadcrumb text-left">
            <div className="container">
              <ul className="breadcrumbs-custom-path">
                <li><a href="/">HOME</a></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> SCIENTIFIC GUIDELINES</li>
              </ul>
            </div>
          </section>
        </div>
        <section className="w3l-index3" id="about1">
          <div className="midd-w3 py-5">
            <div className="container py-lg-5 py-md-3">
              <div className="row">
                <div className="col-lg-12 themeheading">
                  <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                </div>
                <div className="col-lg-12 pl-lg-5 mb-5">
                  <div className="header-title">
                    <span className="sub-title">GUIDELINES FOR SCIENTIFIC PAPER/POSTER PRESENTATION AT THE PG CONVENTION</span>

                  </div>

                  <div className="col-md-12 text-left">
                    <p><b>1: </b> Registration in the PG Convention is a <b>Prerequisite</b> for participating in the Scientific Papers/Posters Presentation and is open for all the Post Graduate Students and Faculty members of Oral Medicine and Radiology.</p>
                    <p><b>2: </b> Once you have filled the registration form, you will be provided with a <b>Registration ID.</b></p>
                    <p><b>3: </b> While uploading the abstract, you will be required to fill in your <b>Registration ID.</b></p>
                    <p><b>4: </b> Each registered participant is allowed to participate in either Scientific Paper or Poster Presentation.</p>
                    <p>&nbsp;

                    </p>
                    <h5 className="orangetext">PAPER PRESENTATION:</h5>
                    <ol className="guildlines">
                      <li>The Paper Presentation will take place in two distinct categories:<br />
                        <b> 1: Faculty Presentation<br /> 2: Student Presentation</b> </li>
                      <li>In the Paper Presentation category, participation is restricted to a single participant per entry.</li>
                      <li>Presented papers can fall under the categories of Original Research, Case Report and Review Article.</li>
                      <li>Structured Abstracts are mandatory for Original Research.</li>
                      <li>Non-structured abstracts are required for Case Reports, Reviews, and Posters.</li>
                      <li>Abstracts must adhere to a 300-word limit and may be sent in MS Word Format, using Times New Roman font size 14 for Headings and 12 for Content via the provided Google Form link on the convention's website.</li>
                      <li>Paper presentations must be created using MS PowerPoint 2016 or a newer version.</li>
                      <li>The First Slide of the Power Point Presentation must have the Name of the Topic and the Registration ID of the Presenter. To maintain impartial assessment, make certain that your paper OMITS any reference to the presenter's name, guide's name, or institution.</li>
                      <li>The allotted time for Paper Presentation is :<br />
                        <b>Students:</b> - 6 minutes, followed by a 2-minute discussion period.<br /> <b>Faculty:</b> 8minutes, followed bya 2-minute discussion period.</li>
                      <li>The Final Date for Abstract Submission is 30th April 2024.</li>
                      <li>The Final Date for submission of the Power Point Presentation is 10th August 2024.</li>
                    </ol>

                    <p>&nbsp;</p>
                    <h5 className="orangetext">POSTER PRESENTATION:</h5>
                    <ol className="guildlines">
                      <li>Only one participant is allowed per poster.</li>
                      <li>In the Poster Presentation category, up to two participants are allowed per entry.</li>
                      <li>Exclusively submit E-posters in JPEG format, ensuring a minimum resolution of 600 dpi and a 16:9 aspect ratio in landscape mode. Ensure the poster is devoid of any mention of the presenter's name, guide's name, or institution. Remember to include the Registration ID at the top right corner.</li>
                      <li>Participants of the selected poster will be required to present it before the jury for a duration of 3 minutes, followed by a 2-minute discussion.</li>
                      <li>The Final Date for Abstract Submission is 30th April 2024.</li>
                      <li>The Final Date for the Final Poster in jpg Format is 10th August 2024.</li>
                      <li>The decision of the scientific committee is conclusive and final.</li>
                    </ol>
                    <p>&nbsp;</p>
                    <h5 className="orangetext">QUIZ COMPETITION:</h5>
                    <ol className="guildlines">
                      <li>Participation is open to registered individuals only.</li>
                      <li>Each Team may comprise of 3 Participants.</li>
                      <li>The 1st Round of the Quiz comprises multiple-choice questions (MCQs).</li>
                      <li>The Top 8 Teams shall qualify for the Oral Round.</li>
                      <li>The quiz master reserves the right to make decisions on unforeseen circumstances.</li>
                      <li>Only one team per institute will be allowed.</li>
                    </ol>
                    For Scientific Related Query Contact:<br /> <b>Dr. Haider Iqbal - <a href="tel:9935870150">+91-9935870150</a></b>, <b>Dr. Yakshi Singh- <a href="tel:8317064042">+91-8317064042</a></b>
                  </div>




                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default ScientificGuidelines;