import React, { Component } from "react";
import DC1 from '../images/dc1.jpeg';
import DC2 from '../images/dc2.jpeg';
import DC3 from '../images/dc3.jpeg';
import { NavLink } from "react-router-dom";
class Hotels extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> DEBATE COMPETITION</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container py-lg-5 py-md-3">
                            <div className="row">

                                <div className="col-lg-12 pl-lg-5 mb-5">
                                    <div className="header-title">
                                        <span className="sub-title">DEBATE COMPETITION</span>

                                    </div>

                                    <div className="col-md-12 text-center mt-2">
                                        <img src={DC1}/>
                                    </div>
                                    <div className="col-md-12 text-center mt-2">
                                        <img src={DC2}/>
                                    </div>
                                    <div className="col-md-12 text-center mt-2">
                                        <img src={DC3}/>
                                    </div>
<div className="col-md-12 text-center mt-2"><p><NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSezS3QfU5wUFr_9ipVrJRAYuebWjZl7neQVLmfQBVHthGIDhw/viewform?pli=1" target="_blank" className="btn btn-primary" >Register Now</NavLink></p></div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default Hotels;