import React, { Component, useState } from "react";
import Place1 from "../images/place1.jpg";
import Place2 from "../images/place2.jpg";
import Place3 from "../images/place3.jpg";
import Place4 from "../images/place4.jpg";
import Place5 from "../images/place5.jpg";
import Place6 from "../images/place6.jpg";
import Place7 from "../images/place7.jpg";
import Place8 from "../images/place8.jpg";
import Place9 from "../images/place9.jpg";
import Cuisine1 from "../images/cuisine1.jpg";
import Cuisine2 from "../images/cuisine2.jpg";
import Cuisine3 from "../images/cuisine3.jpg";
import Cuisine4 from "../images/cuisine4.jpg";
import Cuisine5 from "../images/cuisine5.jpg";
import Shop1 from "../images/shop1.jpg";
import Shop2 from "../images/shop2.jpg";
import Shop3 from "../images/shop3.jpg";
import Shop4 from "../images/shop4.jpg";
import Ayodhya from "../images/ayodhya.jpg";
import Agra from "../images/agra.jpg";
import Varanasi from "../images/varanasi.jpg";
import Modal from 'react-bootstrap/Modal';
export const PlacesToVisit = () => {
    const [imambara, setImambara] = useState(false);
    const [janeshwarmishra, setJaneshwarmishra] = useState(false);
    const [Residency, setResidency] = useState(false);
    const [Rumygate, setRumygate] = useState(false);
    const [Echogarden, setEchogarden] = useState(false);
    const [Zoo, setZoo] = useState(false);
    const [Rama, setRama] = useState(false);
    const [Gomti, setGomti] = useState(false);
    const [Lulu, setLulu] = useState(false);
    return (
        <>
            <Modal
                size="lg"
                show={imambara}
                onHide={() => setImambara(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Bada Imambara
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place1} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">The Bara Imambara is an architectural marvel located in Lucknow, Uttar Pradesh, India. It was built by Nawab Asaf-ud-Daula in 1784 as a relief measure for a major famine that struck the region. The complex includes the Bara Imambara, the Asfi Mosque, and the Bhulbhulaiya (the Labyrinth).</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Here are some key features of the Bara Imambara:</p>

                            <p><b>1- Bara Imambara:</b> This is the main building and the largest arched construction without support beams. The central hall of the Imambara, known as the "Baradari," is one of the largest arched constructions of its kind.</p>

                            <p><b>2- Asfi Mosque:</b> The mosque is located to the west of the Imambara and is an integral part of the complex. It is also known as Asfi Masjid.</p>

                            <p> <b>3- Bhulbhulaiya (Labyrinth):</b> This is a maze of narrow, winding passages on the upper floor of the Imambara. It is said to be designed to confuse any intruder and is a popular attraction among visitors.</p>

                            <p><b>4- Gates:</b> The complex has several gates, including the Rumi Darwaza, which is an imposing gateway and an example of Awadhi architecture. The Rumi Darwaza is often considered the symbol of Lucknow.</p>

                            <p><b>5- Construction without pillars:</b> One of the unique features of the Bara Imambara is that it is one of the largest structures in the world without the support of beams or pillars. The central hall is said to be about 50 meters long and 15 meters high.</p>

                            <p><b>6- Famine relief project:</b> The construction of the Bara Imambara was initiated by Nawab Asaf-ud-Daula as a measure to provide employment to the people during a severe famine. The project aimed to provide sustenance to the people in exchange for their labor.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>




            <Modal
                size="lg"
                show={janeshwarmishra}
                onHide={() => setJaneshwarmishra(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Janeshwar Mishra Park
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place2} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Janeshwar Mishra Park is a large public park located in Lucknow, Uttar Pradesh, India. It is one of the newer parks in the city and is named after Janeshwar Mishra, a prominent politician and freedom fighter from Uttar Pradesh. The park is often referred to as J. P. Park and is known for its expansive green spaces, recreational facilities, and a variety of attractions.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features of Janeshwar Mishra Park:</p>

                            <p><b>1- Size and Layout:</b> The park is one of the largest in Asia, covering an area of over 376 acres. It is designed with a mix of green landscapes, water bodies, and recreational areas.</p>

                            <p><b>2- Amphitheater:</b> Janeshwar Mishra Park features an amphitheater where cultural events, performances, and public gatherings take place. It serves as a venue for various cultural and entertainment activities.</p>

                            <p> <b>3- Walking and Jogging Tracks:</b> The park provides well-maintained walking and jogging tracks, making it a popular spot for locals to engage in physical activities. The tracks wind through the greenery, offering a pleasant environment for exercise.</p>

                            <p><b>4- Lake and Boating:</b> There is a lake within the park where visitors can enjoy boating. The presence of water bodies adds to the overall ambiance of the park</p>

                            <p><b>5- Children's Play Area::</b> Janeshwar Mishra Park has designated areas for children to play, featuring playground equipment and recreational facilities suitable for kids.</p>

                            <p><b>6- Cycling Track:</b> The park includes a dedicated cycling track, encouraging people to engage in cycling for fitness and recreation.</p>
                            <p><b>7- Floral Gardens:</b> The park is adorned with well-maintained gardens and floral arrangements, providing a serene and aesthetically pleasing environment.</p>
                            <p><b>8- Statues and Monuments:</b> There are statues and monuments within the park, including a memorial dedicated to Janeshwar Mishra, commemorating his contributions to Indian politics.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Residency}
                onHide={() => setResidency(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Residency
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place3} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">The Residency is a historic complex located in Lucknow, Uttar Pradesh, India. It served as the official residence of the British Resident General who was a representative of the British East India Company during the period of British rule in India. The Residency is famous for its association with the events of the Indian Rebellion of 1857, also known as the Sepoy Mutiny or the First War of Indian Independence.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features and historical significance of the Residency:</p>

                            <p><b>1- Architectural Heritage:</b> The Residency complex includes a group of buildings, gardens, and other structures. The architecture is a blend of British and local styles, reflecting the colonial influence.</p>

                            <p><b>2- Indian Rebellion of 1857:</b> The Residency gained historical prominence during the Indian Rebellion of 1857. The complex became a focal point of the conflict, with the British residents and a small group of European and Indian soldiers taking refuge here during the siege of Lucknow.</p>

                            <p> <b>3- Siege of Lucknow:</b> The Residency endured a prolonged siege from May 30, 1857, to November 27, 1857. The British residents, along with their families and supporters, faced constant attacks from Indian rebels. The siege was a critical episode in the larger context of the 1857 uprising.</p>

                            <p><b>4- Ruins and Memorials:</b> Today, the Residency is mostly in ruins, with many buildings showing the scars of battle. The site has been preserved as a memorial to the soldiers and civilians who lost their lives during the siege. There are graves, memorials, and plaques dedicated to those who served and perished during the conflict.</p>

                            <p><b>5- Museum:</b> The Residency has a museum that houses artifacts, documents, and exhibits related to the events of 1857. It provides visitors with a detailed insight into the history and significance of the Residency during the rebellion.</p>

                            <p><b>6- Gardens:</b> The complex includes well-maintained gardens that add to the peaceful ambiance of the site. Visitors can explore the grounds and appreciate the historical and architectural elements.</p>


                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Rumygate}
                onHide={() => setRumygate(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Rumi Darwaza
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place4} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Rumi Darwaza, also known as the Turkish Gate, is an iconic historical monument in Lucknow, Uttar Pradesh, India. </p>
                            <p className="mt-3">Here are some key details about Rumi Darwaza:</p>
                            <p><b>1- Architectural Style:</b> Rumi Darwaza was constructed in 1784 by Nawab Asaf-ud-Daula. The structure is known for its impressive architectural style, which combines elements of Persian and Awadhi architecture.</p>
                        </div>
                        <div className="col-md-12">




                            <p className="mt-3"><b>2- Symbol of Lucknow:</b> Rumi Darwaza is often considered a symbol of Lucknow and is one of the most recognizable landmarks in the city. It stands as a testament to the rich cultural and historical heritage of Lucknow.</p>

                            <p> <b>3- Height:</b> The gate is around 60 feet tall and has a large arched entrance. It was modeled after the Sublime Porte (Bab-iHümayun) in Istanbul, Turkey.</p>

                            <p><b>4- Purpose:</b> Rumi Darwaza was originally built as an entrance to the city of Lucknow. It served both functional and symbolic purposes, reflecting the grandeur of the city during the Nawabi era.</p>

                            <p><b>5- Festivals:</b> The gate has been traditionally illuminated during various festivals, adding to its majestic appearance.</p>




                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Echogarden}
                onHide={() => setEchogarden(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Hazratganj Street
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place5} width="100%" className="" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-1">Hazratganj is a popular shopping and commercial area located in the heart of Lucknow, the capital city of the Indian state of Uttar Pradesh. It is one of the oldest and most well-known markets in the city, with a rich history and cultural significance.<br /><br /> Here are some key points about Hazratganj:</p>
                        </div>
                        <div className="col-md-12">
                            <p><b>1- History:</b> Hazratganj was established in the mid-19th century during the British colonial period. It was originally designed as a market area and a leisure space for the British residents of Lucknow.</p>

                            <p><b>2- Named after Begum Hazrat Mahal:</b> The area is named after Begum Hazrat Mahal, the wife of Nawab Wajid Ali Shah, who played a significant role in the Indian Rebellion of 1857 against the British East India Company.</p>

                            <p> <b>3- Shopping Destination:</b> Hazratganj is known for its wide array of shops, boutiques, and department stores. It is a popular destination for shopping, offering a mix of traditional and modern retail outlets, including clothing stores, jewelry shops, bookstores, and more.</p>

                            <p><b>4- Landmarks:</b> The area is home to several landmarks, including historical buildings, theaters, and parks. Some notable landmarks include the Vidhan Sabha (the State Legislative Assembly), the General Post Office (GPO), and the iconic Mayfair Building.</p>

                            <p><b>5- Cultural Hub:</b> Hazratganj is not only a commercial hub but also a cultural and social center. It hosts various cultural events, festivals, and celebrations throughout the year. The area is also known for its cafes, restaurants, and street food stalls.</p>

                            <p><b>6- Accessibility:</b> Located in the central part of Lucknow, Hazratganj is easily accessible from various parts of the city. It is well-connected by road, and the area is a hub for public transportation.</p>

                            <p><b>7- Heritage Walks:</b> The Lucknow Development Authority (LDA) has organized heritage walks in Hazratganj to showcase the historical and architectural significance of the area. These walks provide insights into the rich cultural heritage of Lucknow.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>




            <Modal
                size="lg"
                show={Zoo}
                onHide={() => setZoo(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Clock Tower
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place6} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">The Clock Tower in Lucknow, India, is a historical landmark that is commonly known as the Husainabad Clock Tower. Here are some key details about it:</p>
                            <p><b>1- Location:</b> The Clock Tower is located in the Husainabad area of Lucknow, near the Rumi Darwaza. It stands in the vicinity of the Bara Imambara and the Chota Imambara.</p>
                        </div>
                        <div className="col-md-12">




                            <p><b>2- Construction:</b> The Husainabad Clock Tower was constructed in 1881 by Nawab Nasir-ud-din Haider, the then ruler of Awadh. It was built as part of the beau</p>

                            <p> <b>3- Design:</b> The architecture of the Clock Tower is a fine example of Victorian-Gothic style. The tower is about 67 meters tall and is adorned with fine details and embellishments. The clock on the tower was designed and manufactured by the famous clockmaker, Gillett & Johnston Ltd. of London.</p>

                            <p><b>4- Purpose:</b> The primary purpose of the Clock Tower was to provide the residents of Lucknow with a reliable means of checking the time. It served both functional and ornamental purposes, contributing to the architectural grandeur of the city.</p>

                            <p><b>5- Clock Mechanism:</b> The clock itself is a mechanical marvel and was designed to play different tunes at specific times of the day. The mechanism was imported from London and was known for its accuracy.</p>

                            <p><b>6- Restoration:</b> Over the years, the Clock Tower has undergone several restoration efforts to preserve its historical significance. The restoration work aimed at maintaining the original charm of the structure.</p>




                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Rama}
                onHide={() => setRama(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Ramabai Ambedkar Park
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place7} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">
                                Rani Laxmi Bai Park, also known as Ramabai Ambedkar Park, is a public park in Lucknow, Uttar Pradesh, India. The park is dedicated to Rani Laxmi Bai of Jhansi, a prominent figure in the Indian Rebellion of 1857, and Dr. B. R. Ambedkar, the principal architect of the Indian Constitution and a social reformer.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Here are some key features and information about Rani Laxmi Bai Park:</p>

                            <p><b>1- Location:</b> The park is located in the Gomti Nagar area of Lucknow and is easily accessible.</p>

                            <p><b>2- Statues and Memorials:</b> Rani Laxmi Bai Park features statues and memorials dedicated to Rani Laxmi Bai and Dr. B. R. Ambedkar. The statues pay tribute to their contributions to Indian history and society.</p>

                            <p> <b>3- Architecture:</b> The park has impressive architecture with well-designed pathways, landscaped gardens, and water features.</p>

                            <p><b>4- Ambedkar Memorial:</b> The park includes the Dr. B. R. Ambedkar Memorial, which commemorates the life and work of Dr. B. R. Ambedkar. The memorial showcases his ideals and contributions to social justice and equality.</p>

                            <p><b>5- Rani Laxmi Bai Memorial:</b> The park also pays homage to Rani Laxmi Bai, the warrior queen of Jhansi, who played a significant role in the Indian Rebellion of 1857 against British rule.</p>

                            <p><b>6- Cultural and Historical Significance:</b> Rani Laxmi Bai Park is not only a green space but also a place of cultural and historical significance. It serves as a reminder of the rich history and diverse contributions of these two iconic figures.</p>

                            <p><b>7- Visitors:</b> The park attracts visitors, tourists, and locals alike, providing a peaceful environment for relaxation and reflection.</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Gomti}
                onHide={() => setGomti(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Gomti River Front
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place8} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">
                                The Gomti Riverfront in Lucknow is a significant urban development project that focuses on the beautification and revitalization of the Gomti River and its surroundings. The project aims to transform the riverfront into an attractive and sustainable area for recreation, tourism, and environmental conservation.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">Key features of the Gomti Riverfront in Lucknow:</p>

                            <p><b>1- Location:</b> The Gomti River runs through the city of Lucknow, and the riverfront development project spans various stretches along its course.</p>

                            <p><b>2- Beautification:</b> The project involves landscaping, gardens, and green spaces along the riverbanks to enhance the aesthetic appeal of the area.</p>

                            <p> <b>3- Promenades and Walkways:</b> The riverfront features well-designed promenades and walkways, providing residents and visitors with pleasant spaces for walking, jogging, and enjoying the scenic views of the river.</p>

                            <p><b>4- Recreational Facilities:</b> Parks, gardens, and recreational facilities have been developed along the riverfront to promote outdoor activities and community engagement.</p>

                            <p><b>5- Cultural Spaces:</b> Some sections of the Gomti Riverfront may include cultural spaces, amphitheaters, and open areas for hosting events, festivals, and cultural activities.</p>

                            <p><b>6- Bridges and Connectivity:</b> The project might include the construction or renovation of bridges, improving connectivity between different parts of the city.</p>

                            <p><b>7- Environmental Initiatives:</b> Efforts are often made to address environmental concerns, such as water quality improvement, waste management, and conservation of the river ecosystem.</p>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={Lulu}
                onHide={() => setLulu(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        LULU Mall Lucknow
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Place9} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">
                                LuLu Mall Lucknow is strategically located on NH 27, the Lucknow-Sultanpur National Highway which is a 126 kms long 4-lane highway on Amar Shaheed Path, with a dedicated service road for the Mall. The Mall is just 20 minutes from the city centre and the Airport. </p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">The Mall is located within Sushant Golf City with a total development of 1,85,800 sq. m. The mixed-use development of Sushant Golf City has luxury housing, educational institutes, clubs, hospitals and a magnificent 18 hole golf course spread across 6.8 lakh sq.m.</p>
                            <p className="mt-3">The Mall will house over 300 national and international brands and will feature a 6000 sq. m. Family Entertainment Centre as well as a 1600 seater Food Court, with a wide range of dining options. The parking facility of the Mall includes a highly sophisticated traffic management system and a Multi Level Car Park, which is directly connected to the Mall on all floors. Lulu Mall Lucknow will be a destination Mall for the people of Lucknow and neighbouring cities.</p>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><a href="/">HOME</a></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> PLACES TO VISIT</li>
                        </ul>
                    </div>
                </section>
            </div>
            <div className="w3l-homeblog" id="">

                <div className="container py-lg-5 py-md-4">
                    <div className="col-lg-12 themeheading">
                        <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                    </div>
                    <div className="header-title">
                        <span className="sub-title">PLACES TO VISIT</span>

                    </div>
                    <div className="row top-pics ">
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place1} alt="" className="img-fluid" />

                            <p className="title">Bada Imambara</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setImambara(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place2} alt="" className="img-fluid" />
                            <p className="title">Janeshwar Mishra Park</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setJaneshwarmishra(true)}> Read More.. </a></div>
                        </div>


                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place3} alt="" className="img-fluid" />
                            <p className="title">Residency</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setResidency(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place4} alt="" className="img-fluid" />
                            <p className="title">Rumi Darwaza</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setRumygate(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place5} alt="" className="img-fluid" />
                            <p className="title">Hazratganj Street</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setEchogarden(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place6} alt="" className="img-fluid" />
                            <p className="title">Clock Tower</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setZoo(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place7} alt="" className="img-fluid" />
                            <p className="title">Ramabai Ambedkar Park</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setRama(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place8} alt="" className="img-fluid" />
                            <p className="title">Gomti River Front</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setGomti(true)}> Read More.. </a></div>
                        </div>

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Place9} alt="" className="img-fluid" />
                            <p className="title">Lulu Mall</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setLulu(true)}> Read More.. </a></div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );

}
export default PlacesToVisit;