import React, { Component } from "react";
import { Table } from "react-bootstrap";
class ScientificSchedule extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> SCIENTIFIC SCHEDULE</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container-fluid py-lg-5 py-md-3">
                            <div className="row">
                                <div className="col-lg-12 themeheading">
                                    <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                                </div>
                                <div className="col-lg-12 pl-lg-5 mb-5">
                                    <div className="header-title">
                                        <span className="sub-title">IAOMR NATIONAL PG CONVENTION
                                            22nd – 24th AUGUST 2024
                                        </span>

                                    </div>

                                    <div className="col-lg-12 pl-lg-5 mt-5">
                                        <table className="table table-bordered">
                                            <tr>
                                                <td colSpan={3} align="center">
                                                    <h4 align="center" className="orangetext">DAY 1- 22nd AUGUST 2024
                                                    </h4>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td width="250px;">
                                                    09:00 AM - 10:00 AM
                                                </td>
                                                <td colSpan={2}>
                                                    <h6 className="orangetext">REGISTRATION AND BREAKFAST</h6>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td rowSpan={4}>10:00 AM - 01:00 PM</td>
                                                <td>Pre- Conference Workshop-I </td>

                                            </tr>
                                            <tr>

                                                <td>Pre- Conference Workshop-II </td>

                                            </tr>
                                            <tr>

                                                <td>Pre- Conference Workshop-III </td>

                                            </tr>
                                            <tr>

                                                <td>Pre- Conference Workshop-IV</td>

                                            </tr>
                                            <tr>
                                                <td>01:00 PM - 02:00 PM</td>
                                                <td colSpan={2} align="center" className="orangetext">LUNCH</td>

                                            </tr>
                                            <tr>
                                                <td>02:00 PM - 04:00 PM</td>
                                                <td colSpan={2} >Pre-Conference Workshop Contd.</td>

                                            </tr>
                                        </table>

                                    </div>

                                    <div className="col-lg-12 pl-lg-5 mt-5">
                                        <table className="table table-bordered">
                                            <tr>
                                                <td colSpan={6}>
                                                    <h4 align="center" className="orangetext">DAY 2- 23rd AUGUST 2024
                                                    </h4>
                                                </td>

                                            </tr>


                                            <tr>
                                                <td width="250px;">09:00 AM - 10:00 AM </td>
                                                <td>REGISTRATION & BREAKFAST</td>

                                            </tr>
                                            <tr>
                                                <td>10:00 AM - 11:00 AM</td>
                                                <td>GUEST LECTURE- 1 / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>11:00 AM - 12:00 PM</td>
                                                <td>GUEST LECTURE- 2 / WRITTEN QUIZ COMPETITION / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>12:00 PM - 01:00 PM</td>
                                                <td >
                                                    <h4 align="" className="orangetext">INAUGURATION CEREMONY</h4>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>01:00 PM - 02:00 PM</td>
                                                <td >
                                                    <h4 align="" className="orangetext">LUNCH</h4>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>02:00 PM - 03:00 PM</td>
                                                <td>GUEST LECTURE- 3 / E-POSTERS DISPLAYED / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>03:00 PM - 04:00 PM</td>
                                                <td>GUEST LECTURE- 4 / PRESENTATION OF SELECTED E-POSTERS / PAPER PRESENTATION </td>

                                            </tr>
                                            <tr>
                                                <td>04:00 PM - 05:00 PM</td>
                                                <td ><h4 align="" className="orangetext">QUIZ – MAIN STAGE</h4> </td>

                                            </tr>
                                            <tr>
                                                <td>07:00 PM ONWARDS</td>
                                                <td ><h4 align="" className="orangetext">BANQUET</h4></td>

                                            </tr>

                                        </table>
                                    </div>

                                    <div className="col-lg-12 pl-lg-5 mt-5">
                                        <table className="table table-bordered" >
                                            <tr>
                                                <td colSpan={6}>
                                                    <h4 align="center" className="orangetext">DAY 3 – 24th AUGUST 2024</h4>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td width="250px;">08:00 AM - 10:00 AM </td>
                                                <td>PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>10:00 AM - 11:00 AM</td>
                                                <td>GUEST LECTURE- 5 / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>11:00 AM - 12:00 PM</td>
                                                <td>GUEST LECTURE- 6 / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>12:00 PM - 01:00 PM</td>
                                                <td>GUEST LECTURE- 7 / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>01:00 PM - 02:00 PM</td>
                                                <td>GUEST LECTURE- 8 / PAPER PRESENTATION</td>

                                            </tr>
                                            <tr>
                                                <td>02:00 PM - 03:00 PM</td>
                                                <td >
                                                    <h4 align="" className="orangetext">LUNCH</h4>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>03:00 PM ONWARDS</td>
                                                <td >
                                                    <h4 align="cnter" className="orangetext">VALEDICTORY CEREMONY</h4>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default ScientificSchedule;