import React, { Component } from "react";

import { NavLink, Link } from "react-router-dom";

class Footer extends Component {
    render() {

        return (
            <>
                <section className="w3l-footer-29-main">
                    <div className="footer-29 py-5">
                        <div className="container-fluid py-lg-4">
                            <div className="row footer-top-29">
                                <div className="col-lg-7 col-md-6 col-sm-12 footer-list-29 footer-4 mt-lg-0 mt-5">
                                    <h6 className="footer-title-29 ">Venue Location</h6>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3562.368683414775!2d80.9451002!3d26.7645153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb0b405ce00b%3A0x5b8f3c06c0a530e!2sSardar%20Patel%20Post%20Graduate%20Institute%20of%20Dental%20%26%20Medical%20Sciences!5e0!3m2!1sen!2sin!4v1704434290274!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>

                                <div className="col-lg-5 col-md-6 col-sm-12 footer-list-29 footer-4 mt-lg-0 mt-5 text-left">
                                    <h6 className="footer-title-29 ">Contact Info </h6>
                                    <p className="text-yellow"><strong className="text-white">Address :</strong> Department of Oral Medicine and Radiology
                                        Sardar Patel PG Institute of Dental and Medical Sciences<br />Chaudhary Vihar, Raebareli Road, Uthrethia, Lucknow - 226029</p>
                                    <p className="my-2 text-yellow"><strong className="text-white">Phone :</strong><br />
                                        <b>Dr Abhishek Sinha (Organizing Chairman) :</b>    <a className="text-yellow" href="tel:+91-8948116600">+91-8948116600</a><br />
                                        <b>Dr Sunita Srivastava (Organizing Secretary) :</b>
                                        <a href="tel:+91-7607722454" className="text-yellow">+91-7607722454</a><br />
                                        <b>Dr Anuj Mishra (Conference Treasurer):</b><a href="tel:+91-8127005593" className="text-yellow">+91-8127005593</a></p>
                                    <p className="text-white"><strong>Email :</strong> <a className="text-yellow" href="mailto:iaomrpgconvention2024@gmail.com">iaomrpgconvention2024@gmail.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="w3l-copyright">
                    <div className="container">
                        <div className="row bottom-copies">
                            <p className="col-lg-12 copy-footer-29">© 2024 All rights reserved. Design by <a
                                href="https://digisurgeon.in/" target="_blank">
                                DigiSurgeon Info Solutions</a></p>



                        </div>
                    </div>



                </section>
            </>
        );
    }
}
export default Footer;
