import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
 
import Button from '@mui/material/Button';
import Constants from "../../utils/Constants";
 
import { NavLink, Link, useNavigate } from "react-router-dom";

export const IDCards = () => {
    const [numbers, setNumbers] = useState();
    const [showLoading, setShowLoading] = useState(false);
    

    const navigate = useNavigate();
    if (!localStorage.getItem('user')) {
        console.log("yesy");
        navigate("/admin-login");
    }

 const handlegenerator = () => {
        if(numbers > 0){
            const url = `/download-admit-card/${numbers}`;

            // Open the URL in a new tab
            window.open(url, '_blank', 'noopener,noreferrer');
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please Enter Number of ID Cards!',
            })
        }
    }

 

    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><Link to="/dashboard">HOME</Link></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> DOwnload ID Cards</li>
                        </ul>
                    </div>
                </section>
            </div>
            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container-fluid py-lg-5 py-md-3">
                        <div className="row">

                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">Download ID Cards</span>

                                </div>

                                <div className="container mt-5">
                                <div className="row justify-content-center">
                                        <div className="col-md-3 form-group">
                                            <label>Number of ID Cards</label>
                                            <input type="number" name="numbers" value={numbers} onChange={(e)=>setNumbers(e.target.value)} className="form-control"/>      
                                            <input type="submit" value="Generate ID Cards" className="btn btn-success mt-4" onClick={handlegenerator}/>                                    
                                        </div>
                                         
                                        
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default IDCards;