import React, { useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import Dropzone from "react-dropzone";
import Constants from "../utils/Constants";
import Loader from "../utils/Loader";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QR from '../images/qrcode.png';
import { useNavigate } from "react-router-dom";

import Radio from '@mui/material/Radio';
export const OnlineRegistration = () => {
    ///// for checkboxes ///////
    const [disabled, setDisabled] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedpreCheckboxes, setSelectedPreCheckboxes] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [photoName, setPhotoname] = useState('');
    const [proofName, setProofname] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePresentationsChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {

            setSelectedCheckboxes((prevSelectedCheckboxes) => [...prevSelectedCheckboxes, value]);
        } else {
            console.log("No");
            setSelectedCheckboxes((prevSelectedCheckboxes) =>
                prevSelectedCheckboxes.filter((checkbox) => checkbox !== value)
            );
        }


    };
    const handlePreconferenceChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {

            setSelectedPreCheckboxes((prevSelectedCheckboxes) => [...prevSelectedCheckboxes, value]);
        } else {

            setSelectedPreCheckboxes((prevSelectedCheckboxes) =>
                prevSelectedCheckboxes.filter((checkbox) => checkbox !== value)
            );
        }


    };


    ///// for checkboxes ///////






    const [photo, setPhoto] = useState(null);
    const [name, setName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [institute, setInstitute] = useState('');
    const [faculty, setFaculty] = useState('');
    const [address, setAddress] = useState('');
    const [registrationno, setRegistrationno] = useState('');
    const [type, setType] = useState('');
    const [membertype, setMembertype] = useState('');
    const [iaomrmember,setIaomrmember]=useState('');
    const [preconference, setPreconference] = useState('');
    const [transactiondetails, setTransactiondetails] = useState('');
    const [transactiondate, setTransactiondate] = useState('');
    const [screenshot, setScreenshot] = useState('');
    const [title, setTitle] = useState('');
    const [paymentproof, setPaymentproof] = useState('');
    const navigate = useNavigate();

    const handleRegistration = (e) => {
        e.preventDefault();
        setShowLoading(true);
        if (paymentproof === '') {
            Swal.fire("Please upload payment proof");
        }
        if (photo === '') {
            Swal.fire("Please upload photo");
        }
        let payload = {
            name: name,
            gender: gender,
            address: address,
            mobile: mobile,
            email: email,
            institute: institute,
            faculty: faculty,
            registration_no: registrationno,
            transaction_details: transactiondetails,
            transaction_date: transactiondate,
            type: type,
            membertype: membertype,
            iaomrmember:iaomrmember,
            file: paymentproof,
            photo: photo

        }
        axios({
            method: "POST",
            url: Constants.baseUrl + "online-registration",
            data: payload,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {

                navigate("/thanks/" + res.data.registration_id);
                setShowLoading(false);

                setName('');
                setGender('');
                setDob('');
                setMobile('');
                setEmail('');
                setInstitute('');
                setFaculty('');
                setRegistrationno('');
                setType('');
                setMembertype('');
                setIaomrmember('');
                setTransactiondetails('');
                setTransactiondate('');
                setScreenshot('');
                setTitle('');
                setPaymentproof('');
                setPhoto('');
            })
            .then((data) => {
                console.log(data)
            })
            .catch(error => {
                setShowLoading(false);
                Swal.fire({
                    icon: "Warning",
                    title: "Oops...",
                    text: "Please Input all required fields",
                    footer: 'Please Check All Fields'
                });
            });



    }
    function getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }
    const handleDrop = (acceptedFiles) => {

        const extension = getFileExtension(acceptedFiles[0]['name']);
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'JPG' || extension === 'JPEG' || extension === 'png' || extension === 'png') {
            if (acceptedFiles[0]['size'] <= 2097152) {
                setPhoto(acceptedFiles[0]);
                setPhotoname("File Name:" + acceptedFiles[0]['name']);
            }
            else {
                Swal.fire("Image Size can not be more than 2 MB.");
                setPhoto("");
            }
        }
        else {
            Swal.fire("Only Images are allowed");
            setPhoto("");
        }
    };
    const handleDropProof = (acceptedFiles) => {
        const extension = getFileExtension(acceptedFiles[0]['name']);
        if (extension === 'jpg' || extension === 'jpeg' || extension === 'JPG' || extension === 'JPEG' || extension === 'png' || extension === 'png') {
            if (acceptedFiles[0]['size'] <= 2097152) {
                setPaymentproof(acceptedFiles[0]);
                setProofname("File Name:" + acceptedFiles[0]['name']);
            }
            else {
                Swal.fire("Image Size can not be more than 2 MB.");
                setPaymentproof("");
            }
        }
        else {
            Swal.fire("Only Images are allowed");
            setPaymentproof("");
        }

    };
    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><a href="/">HOME</a></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> ONLINE REGISTRATION</li>
                        </ul>
                    </div>
                </section>
            </div>
            {
                showLoading ?

                    <Loader />
                    :

                    ''
            }
            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="row">
                            <div className="col-lg-12 themeheading">
                                <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                            </div>
                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">ONLINE REGISTRATION</span>

                                </div>

                                <div className="col-md-12 text-left">
                                    <form onSubmit={handleRegistration}>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label>Faculty/Student</label><br></br>
                                                <select name="faculty" className="form-control" required value={faculty} onChange={(e) => setFaculty(e.target.value)} >
                                                    <option value="">Select</option>
                                                    <optgroup label="Faculty">
                                                        <option value="F-Professor">Professor</option>
                                                        <option value="F-Reader">Reader</option>
                                                        <option value="F-Lecturer">Lecturer</option>
                                                        <option value="F-Pvt. Practitioner">Pvt. Practitioner</option>
                                                    </optgroup>
                                                    <optgroup label="Student">
                                                        <option value="S-STUDENT – MDS 1st YEAR">MDS 1st YEAR</option>
                                                        <option value="S-MDS 2nd YEAR">MDS 2nd YEAR</option>
                                                        <option value="S-MDS 3rd YEAR">MDS 3rd YEAR</option>

                                                    </optgroup>
                                                    <optgroup label="Others">
                                                        <option value="I-Foreign Delegate">Foreign Delegate</option>
                                                        <option value="A-Accompanying Person">Accompanying Person</option>


                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Upload Photo</label>

                                                <Dropzone onDrop={handleDrop} accept="image/*" required>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()} className="uploadphoto">
                                                            <input {...getInputProps()} />
                                                            <p>Drag & drop a photo here, or click to select a file. <span className="text-danger">(MAX-SIZE 2 MB)</span></p>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <p className="filename">{photoName}</p>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Full Name</label>
                                                <input type="text" name="name" required className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Gender</label><br></br>
                                                <select name="gender" className="form-control" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                                    <option value="">Select</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label>Contact Number</label>
                                                <input type="text" name="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control" required></input>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Email ID</label>
                                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="email" className="form-control" required></input>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <label>Address</label>
                                                <textarea onChange={(e) => setAddress(e.target.value)} name="address" className="form-control" required>{address}</textarea>
                                            </div>
                                            {faculty != 'A-Accompanying Person' && <>
                                                <div className="col-md-6 form-group">
                                                    <label>Institute/University</label>
                                                    <input type="text" value={institute} onChange={(e) => setInstitute(e.target.value)} name="institute" required className="form-control"></input>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>IAOMR Member</label>
                                                    <select name="iaomrmember" required className="form-control" value={iaomrmember} onChange={(e) => setIaomrmember(e.target.value)}>
                                                        <option value="">Select</option>

                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                                {iaomrmember=='Yes' &&<>
                                                <div className="col-md-3 form-group">
                                                    <label>IAOMR Member Type</label>
                                                    <select name="membertype" required className="form-control" value={membertype} onChange={(e) => setMembertype(e.target.value)}>
                                                        <option value="">Select</option>

                                                        <option value="ALM">ALM</option>
                                                        <option value="LM">LM</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6 form-group">
                                                    <label>IAOMR Membership Number</label>
                                                    <input type="text" required name="registrationno" className="form-control" value={registrationno} onChange={(e) => setRegistrationno(e.target.value)} ></input>
                                                </div>
                                                </> }

                                            </>}


                                            <div className="col-md-6 form-group">
                                                <label>Transaction Details (Reference Id)</label>
                                                <input type="text" name="transactiondetails" required value={transactiondetails} onChange={(e) => setTransactiondetails(e.target.value)} className="form-control"></input>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Transaction Date</label>
                                                <input type="date" required name="transactiondate" className="form-control" value={transactiondate} onChange={(e) => setTransactiondate(e.target.value)}></input>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label>Upload Payment Screenshot</label>

                                                <Dropzone onDrop={handleDropProof} accept="image/*" required>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()} className="uploadphoto">
                                                            <input {...getInputProps()} />
                                                            <p>Drag & drop a photo here, or click to select a file. <span className="text-danger">(MAX-SIZE 2 MB)</span></p>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <p className="filename">{proofName}</p>
                                                To Get Payment details <a onClick={handleShow}>Click Here</a>
                                            </div>


                                            <div className="col-md-12 form-group">
                                                <label><input type="checkbox" checked={disabled}
                                                    onChange={(e) => setDisabled(e.target.checked)} /> I hereby declare that all information provided in this form is true and accurate. I understand and agree to abide by the rules and regulations set forth by the National PG Convention.
                                                </label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <button disabled={!disabled} type="submit" className="btn btn-success">Register Now</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Account Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-7 pl-lg-5 mb-5">
                            <table className="table">
                                <tr>
                                    <td><b>Beneficiary Name:</b></td>
                                    <td>IAOMR NATIONAL PG CONVENTION 2024</td>
                                </tr>

                                <tr>
                                    <td><b>Bank Account Number:</b></td>
                                    <td>42516036224</td>
                                </tr>

                                <tr>
                                    <td><b>Name of the Bank:</b></td>
                                    <td>STATE BANK OF INDIA</td>
                                </tr>

                                <tr>
                                    <td><b>Branch Name:</b></td>
                                    <td>UTRETHIA</td>
                                </tr>

                                <tr>
                                    <td><b>Account type:</b></td>
                                    <td>CURRENT ACCOUNT</td>
                                </tr>

                                <tr>
                                    <td><b>IFSC code:</b></td>
                                    <td>SBIN0016727</td>
                                </tr>
                            </table>
                            <p align="left"><b>Note:</b></p>
                            <p align="left">1: Payment should be completed before filling the registration form. Payment may be done via UPI (BHIM, Phone Pe, Google Pay,), NEFT, and Internet Banking only.
                            </p>
                            <p align="left">2: Transaction details to be mentioned in the registration form.</p>
                        </div>
                        <div className="col-lg-5 pl-lg-5 mb-5">
                            <img src={QR} width="100%" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );

}
export default OnlineRegistration;