import React, { Component } from "react";
import Abhisheksinha from '../images/abhisheksinha.jpg';
import Sunitasrivastava from '../images/sunitasrivastava.jpg';
import Anujmishra from '../images/anujmishra.jpg';
import HaidarIqbal from '../images/haidariqbal.jpg';
import Yakshisingh from '../images/yakshisingh.jpg';
import Kavleenkaur from '../images/kavleenkaur.jpg';
import Ritambharadwivedi from '../images/ritambharadwivedi.jpg';
import Divyajaiswal from '../images/divyajaiswal.jpg';
import M1 from '../images/m1.jpeg';
import M2 from '../images/m2.jpeg';
import M3 from '../images/m3.jpeg';
import M4 from '../images/m4.jpeg';
import M5 from '../images/m5.jpeg';
import M6 from '../images/m6.jpeg';
import M7 from '../images/m7.jpeg';
import M8 from '../images/m8.jpeg';
import M9 from '../images/m9.jpeg';
import M10 from '../images/m10.jpeg';
import M11 from '../images/m11.jpeg';
import M12 from '../images/m12.jpeg';
import M13 from '../images/m13.jpeg';
import M14 from '../images/m14.jpeg';
import M15 from '../images/m15.jpeg';
import M16 from '../images/m16.jpeg';
import M17 from '../images/m17.jpeg';
import M18 from '../images/m18.jpeg';
import M19 from '../images/m19.jpeg';
import M20 from '../images/m20.jpeg';
import M21 from '../images/m21.jpeg';
import M22 from '../images/m22.jpeg';
import M23 from '../images/m23.jpeg';
import M24 from '../images/m24.jpeg';
import M25 from '../images/m25.jpeg';
import M26 from '../images/m26.jpeg';
import M27 from '../images/m27.jpg';
import M28 from '../images/m28.jpeg';
import M29 from '../images/m29.jpeg';
import M30 from '../images/m30.jpeg';
import M31 from '../images/m31.jpeg';
import M32 from '../images/m32.jpeg';
import M33 from '../images/m33.jpeg';
import M34 from '../images/m34.jpeg';
import M35 from '../images/m35.jpeg';
import M36 from '../images/m36.jpeg';
import M37 from '../images/m37.jpeg';
import M38 from '../images/m38.jpeg';
import M39 from '../images/m39.jpeg';
import M40 from '../images/m40.jpeg';
import M41 from '../images/m41.jpeg';
import M42 from '../images/m42.jpeg';
import M43 from '../images/m43.jpeg';
import M44 from '../images/m44.jpeg';
import M45 from '../images/m45.jpeg';
import M46 from '../images/m46.jpeg';
import M47 from '../images/m47.jpeg';
import M48 from '../images/m48.jpeg';
import M49 from '../images/m49.jpeg';
import M50 from '../images/m50.jpeg';
import M51 from '../images/m51.jpeg';
import M52 from '../images/m52.jpeg';
import M53 from '../images/m53.jpeg';
import M54 from '../images/m54.jpeg';
import M55 from '../images/m55.jpeg';
import M56 from '../images/m56.jpeg';
import M67 from '../images/m67.jpeg';
import M63 from '../images/m63.jpeg';
import M70 from '../images/m70.jpeg';
import M66 from '../images/m66.jpeg';
import M60 from '../images/m60.jpeg';
import M76 from '../images/m76.jpeg';
import M77 from '../images/m77.jpeg';
import M73 from '../images/m73.jpeg';
import M65 from '../images/m65.jpeg';
import M78 from '../images/m78.jpeg';
import M72 from '../images/m72.jpeg';
import M74 from '../images/m74.jpeg';
import M75 from '../images/m75.jpeg';
import M68 from '../images/m68.jpeg';
import M81 from '../images/m81.jpeg';
import M69 from '../images/m69.jpeg';
import M71 from '../images/m71.jpeg';
import M61 from '../images/m61.jpeg';
import M82 from '../images/m82.jpeg';
import M83 from '../images/m83.jpeg';


class OrganizingCommittee extends Component {
  render() {
    return (
      <>
        <div className="inner-banner">
          <section className="w3l-breadcrumb text-left">
            <div className="container">
              <ul className="breadcrumbs-custom-path">
                <li><a href="index.html">HOME</a></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> ORGANIZING COMMITTEE</li>
              </ul>
            </div>
          </section>
        </div>
        <section className="w3l-index3" id="about1">
          <div className="midd-w3 py-5">
            <div className="container-fluid py-lg-5 py-md-3">
              <div className="row ">
                <div className="col-lg-12 themeheading">
                  <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                </div>
                <div className="col-lg-12 pl-lg-5 mb-5">
                  <div className="header-title">
                    <span className="sub-title">CONFERENCE ADVISORS</span>

                  </div>




                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5 ">
                    <div className="row ">
                      <div className="col-lg  mb-5 mx-auto">
                        <img src={M1} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RANJITKUMAR PATIL</h3>

                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M2} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NEETA MISRA</h3>

                      </div>


                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M3} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RAJENDRAGOUDA PATIL</h3>

                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M4} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ARUNA DAS</h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M5} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NITIN AGARWAL</h3>

                      </div>


                    </div>
                  </div>




                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5 ">
                    <div className="header-title">
                      <span className="sub-title">ORGANIZING COMMITTEE</span>

                    </div>
                    <div className="row mt-4">
                      <div className="col-lg  mb-5 mx-auto">
                        <img src={M6} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ABHISHEK SINHA</h3>
                        <p className="comitteeposts">ORGANIZING CHAIRMAN</p>
                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M7} width="100%" className="doctor" />
                        <h3 className="comitteenames">DR. DEEPAK U</h3>
                        <p className="comitteeposts">ORGANIZING CO-CHAIRMAN</p>
                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M8} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SUNITA SRIVASTAVA</h3>
                        <p className="comitteeposts">ORGANIZING SECRETARY</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M9} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANURAG TRIPATHI</h3>
                        <p className="comitteeposts">ORGANISING JOINT SECRETARY</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M10} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANUJ MISHRA</h3>
                        <p className="comitteeposts">CONFERENCE TREASURER</p>
                      </div>


                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">SCIENTIFIC ADVISORS</span>

                    </div>
                    <div className="row mt-4">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M17} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SUNIRA CHANDRA</h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M35} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. PRIYA SINGH</h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M13} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ADIT SRIVASTAVA</h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M67} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. VISHAL MEHROTRA</h3>

                      </div>


                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">SCIENTIFIC COMMITTEE</span>

                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-3 col-sm-6  col-xs-6 mb-5 mx-auto">

                      </div>
                      <div className="col-lg-3 col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M11} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. HAIDER IQBAL</h3>
                        <p className="comitteeposts">SCIENTIFIC COMMITTEE CHAIRMAN</p>
                      </div>
                      <div className="col-lg-3 col-sm-6  col-xs-6 mb-5 mx-auto">
                        <img src={M12} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. YAKSHI SINGH</h3>
                        <p className="comitteeposts">SCIENTIFIC COMMITTEE CO- CHAIRMAN</p>
                      </div>
                      <div className="col-lg-3 col-sm-6  col-xs-6 mb-5 mx-auto">

                      </div>






                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">SCIENTIFIC COMMITTEE MEMBERS</span>

                    </div>
                    <div className="row mt-4">

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M63} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHILPA SINGH</h3>
                        <p className="comitteeposts">SCIENTIFIC COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M70} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DEBANTI GIRI</h3>
                        <p className="comitteeposts">SCIENTIFIC COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M76} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SONALIKA SRIVASTAVA</h3>
                        <p className="comitteeposts">SCIENTIFIC COMMITTEE MEMBER</p>
                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M14} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DIVYA SINGH</h3>
                        <p className="comitteeposts">SCIENTIFIC COMMITTEE MEMBER</p>
                      </div>





                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">REGISTRATION COMMITTEE</span>

                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-4 col-sm-6  col-xs-6 mb-5"></div>


                      <div className="col-lg-3 col-sm-6  col-xs-6 mb-5">
                        <img src={M15} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. KAVLEEN KAUR SETHI</h3>
                        <p className="comitteeposts">REGISTRATION COMMITTEE INCHARGE</p>
                      </div>



                      <div className="col-lg-4 col-sm-6  col-xs-6 mb-5"></div>






                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">REGISTRATION COMMITTEE MEMBERS</span>

                    </div>
                    <div className="row mt-4">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M77} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. GOVIND SACHAN</h3>
                        <p className="comitteeposts">REGISTRATION COMMITTEE MEMBER</p>
                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M66} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. MAMTA SHARMA</h3>
                        <p className="comitteeposts">REGISTRATION COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M60} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NEHA BANSAL</h3>
                        <p className="comitteeposts">REGISTRATION COMMITTEE MEMBER</p>
                      </div>




                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M56} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHIVANI MISHRA</h3>
                        <p className="comitteeposts">REGISTRATION COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M16} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NAZISH KHAN</h3>
                        <p className="comitteeposts">REGISTRATION COMMITTEE MEMBER</p>
                      </div>






                    </div>
                  </div>




                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">HOSPITALITY & RECEPTION COMMITTEE</span>

                    </div>
                    <div className="row mt-4">




                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M18} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RITAMBHARA DWIVEDI</h3>
                        <p className="comitteeposts">HOSPITALITY & RECEPTION COMMITTEE<br /> IN-CHARGE</p>
                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M73} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NIMMI SINGH</h3>
                        <p className="comitteeposts">HOSPITALITY & RECEPTION COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M82} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DEVIKA SINGH</h3>
                        <p className="comitteeposts">HOSPITALITY & RECEPTION COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M19} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SOMI FATIMA</h3>
                        <p className="comitteeposts">HOSPITALITY & RECEPTION COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M20} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHIVANI MAHESHWARI</h3>
                        <p className="comitteeposts">HOSPITALITY & RECEPTION COMMITTEE MEMBER</p>
                      </div>



                    </div>
                  </div>



                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="header-title">
                      <span className="sub-title">PRE- CONFERENCE COMMITTEE</span>

                    </div>
                    <div className="row mt-4">


                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M21} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. PAYAL TRIPATHI</h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M22} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. VASU S SAXENA </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M23} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. YAKSHI SINGH </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M24} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. KAVLEEN KAUR SETHI</h3>

                      </div>


                    </div>
                    <div className="row mt-4">


                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M65} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ARTI SALUJA SACHDEVA</h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M78} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DEVLEENA BHOWMICK </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M72} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHALINI BASU </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M83} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANKITA SRIVASTAVA</h3>

                      </div>


                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">E- SOUVENIR COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M25} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RUCHIKA PRASAD
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M26} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. VIKRAM KHANNA
                        </h3>

                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M28} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANJALI GUPTA
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M27} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DIVYA JAISWAL
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M29} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANKITA DUBEY
                        </h3>

                      </div>
                    </div>
                  </div>




                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">GIFTS AND MEMENTOS COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M30} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SIDDHARTH KUMAR SINGH
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M31} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. VANDANA SINGH
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M32} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RAKESH PRASAD
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M33} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. IMAN ZUBAIR
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M74} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ASHOK KUMAR SINGH
                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M34} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. AYUSHI TUTEJA
                        </h3>

                      </div>
                    </div>
                  </div>


                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">CULTURAL COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">



                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M36} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NEHA AGARWAL
                        </h3>
                        <p className="comitteeposts">CULTURAL COMMITTEE INCHARGE</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M75} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHAKTI AGARWAL
                        </h3>
                        <p className="comitteeposts">CULTURAL COMMITTEE MEMBER</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M81} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SUPRIYA SINGH
                        </h3>
                        <p className="comitteeposts">CULTURAL COMMITTEE MEMBER</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M68} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. PRARTHANA GOVIL
                        </h3>
                        <p className="comitteeposts">CULTURAL COMMITTEE MEMBER</p>

                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M37} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHIVANGI PANDEY
                        </h3>
                        <p className="comitteeposts">CULTURAL COMMITTEE MEMBER</p>

                      </div>


                    </div>
                  </div>


                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">TRANSPORT & ACCOMODATION COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">


                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M38} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SAURABH SRIVASTAVA

                        </h3>
                        <p class="comitteeposts">INCHARGE</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M69} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANKUR SINGH

                        </h3>
                        <p class="comitteeposts">MEMBER</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M71} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANUSHA PRATAP

                        </h3>
                        <p class="comitteeposts">MEMBER</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M61} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SANAULLAH

                        </h3>
                        <p class="comitteeposts">MEMBER</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M39} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ABHISHEK BANERJEE

                        </h3>
                        <p class="comitteeposts">MEMBER</p>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M40} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ADARSH DIXIT
                        </h3>
                        <p class="comitteeposts">MEMBER</p>

                      </div>


                    </div>
                  </div>


                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">INTERNATIONAL CO- ORDINATORS</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M41} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. VIKRAM AHUJA


                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M42} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. PARUL AHUJA


                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M43} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANKIT SAHA

                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                    </div>
                  </div>



                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">BANQUET COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M44} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SUDHIR SHUKLA</h3>

                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M45} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. TANMAY SRIVASTAVA


                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M46} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. KRISHNAKANT CHAUDHARY


                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M47} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHWETA SINGH

                        </h3>

                      </div>


                    </div>
                  </div>


                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">TRADE & FUND-RAISING COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M48} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. AKHILANAND CHAURASIA



                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M49} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. PUJA RAI


                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M50} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANJALI YADAV

                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                    </div>
                  </div>



                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">FELICITATION COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">
                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M51} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DIPTI SINGH




                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M52} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHRUTI SINHA



                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M53} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ISHITA TRIPATHI

                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                    </div>
                  </div>


                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="header-title">
                      <span className="sub-title">TOUR COMMITTEE</span>

                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5">
                    <div className="row">

                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M54} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ANURAG ANAND





                        </h3>

                      </div>
                      <div className="col-lg col-sm-6  col-xs-6 mb-5">
                        <img src={M55} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. VISHAL PANDEY



                        </h3>

                      </div>

                      <div className="col-lg col-sm-6  col-xs-6 mb-5"></div>

                    </div>
                  </div>










                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default OrganizingCommittee;