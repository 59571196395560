import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Constants from "../../utils/Constants";
import { Link } from "react-router-dom";

export const Registrationlist = () => {
    const [tabledata, setTabledata] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const navigate = useNavigate();
    if (!localStorage.getItem('user')) {
        console.log("yesy");
        navigate("/admin-login");
    }
    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this item?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {


                setShowLoading(true);
                let payload = {
                    id: id,

                }

                axios({
                    method: "POST",
                    url: Constants.baseUrl + "delete",
                    data: payload
                })
                    .then((res) => {
                        if (res.data.status == true) {
                            setShowLoading(false);
                            window.location.reload();
                        }
                        else {

                        }
                    });
            }
        });

    };



    const columns: GridColDef[] = [
        { field: 'registration_id', headerName: 'Registration ID', width: 150 },
        { field: 'faculty', headerName: 'FacultyStudent', width: 250 },
        { field: 'name', headerName: 'Name', width: 250 },
        { field: 'college_name', headerName: 'College Name', width: 250 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'category', headerName: 'Category', width: 150 },

        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>

                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(params.row.id)}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },

    ];

    useEffect(() => {
        fetchRegisters();
    }, [])

    const fetchRegisters = async () => {
        await axios.get(Constants.baseUrl + "quiz")
            .then((response) => setTabledata(response.data))
            .catch((error) => {
                console.error(error);
            });
    }


    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><Link to="/dashboard">HOME</Link></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> QUIZ</li>
                        </ul>
                    </div>
                </section>
            </div>
            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container-fluid py-lg-5 py-md-3">
                        <div className="row">

                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">QUIZ</span>

                                </div>

                                <div className="col-md-12 text-left">
                                    <div style={{ width: '100%' }}>
                                        <DataGrid rows={tabledata} components={{
                                            Toolbar: GridToolbar,

                                        }} columns={columns} />
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default Registrationlist;