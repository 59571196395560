import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Constants from "../../utils/Constants";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import QRCode from 'qrcode.react';
export const DownloadAdmitCard = () => {
    const [tabledata, setTabledata] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const { id } = useParams();
    const adurl=Constants.baseUrl + "attendance";
    console.log();
    const [timestamp, setTimestamp] = useState('');
    const navigate = useNavigate();
    if (!localStorage.getItem('user')) {
        console.log("yesy");
        navigate("/admin-login");
    }
    

 
    const generateRandomNumbers = (num) => {
      let randomNumbers = [];
      for (let i = 0; i < num; i++) {
        randomNumbers.push(generateRandomTenDigitNumber());
      }
      return randomNumbers;
    };

    const generateRandomTenDigitNumber = () => {
    return Math.floor(1000000000 + Math.random() * 9000000000);
  };

  const [randomNumbers, setRandomNumbers] = useState(generateRandomNumbers(id));
   
    const divStyle = {
      border: "1px solid #000 !important", 
      margin: "50px auto 20px 20px"
    };
    return (
        <>
            <table width="100%" >
            {randomNumbers.map((number, index) => {
              const uniqueId=  Math.floor(performance.now() * 1000)+number;
              const qrSize = 200; 
              return (
            
                <tr>
                    <td>
                    <table width="100%"   style={divStyle}>
                      <thead>
  <tr>
     
    <td width=" " rowspan="2"> <QRCode value={adurl+"/B-"+uniqueId} size={qrSize}/> </td>
    <td width=" " rowspan="2"><QRCode value={adurl+"L-/"+uniqueId}  size={qrSize}/></td>
    <td width=" " rowspan="2"> <QRCode value={adurl+"D-/"+uniqueId} size={qrSize}/></td>
  </tr>
  </thead>
  <tr>
    <td>{"B-"+uniqueId}</td>
    <td>{"L-"+uniqueId}</td>
    <td>{"D-"+uniqueId}</td>
  </tr>
  <tr>
    <td><b>Breakfast</b></td>
    <td><b>Lunch</b></td>
    <td><b>Dinner</b></td>
  </tr>
   
</table>
                    </td>
                </tr>
              );
            })}
            </table>
        </>
    );

}
export default DownloadAdmitCard;