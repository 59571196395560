import React, { Component, useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Constants from "../utils/Constants";
import { LineWave } from "react-loader-spinner";
import Loader from "../utils/Loader";



export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();


  const handleLoginEvent = (e) => {
    e.preventDefault();

    setShowLoading(true);
    let payload = {
      email: email,
      password: password
    }

    axios({
      method: "POST",
      url: Constants.baseUrl + "login",
      data: payload
    })
      .then((res) => {
        if (res.data.status == true) {
          setShowLoading(true);
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/dashboard");
        }
        else {

        }
      });

  }
  return (
    <>
      <div className="inner-banner">
        <section className="w3l-breadcrumb text-left">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li><a href="index.html">HOME</a></li>
              <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> Login to Admin Panel</li>
            </ul>
          </div>
        </section>
      </div>
      <section className="w3l-index3" id="about1">
        <div className="midd-w3 py-5">
          <div className="container py-lg-5 py-md-3">
            <div className="row text-center">

              <div className="col-lg-12 pl-lg-5 mb-5">
                <div className="header-title">
                  <span className="sub-title">Admin Login</span>

                </div>

              </div>
              <div className="col-lg-4 pl-lg-5 mb-5">
              </div>
              <div className="col-lg-4 pl-lg-5 mb-5">
                <div className="container-sm">
                  <form onSubmit={handleLoginEvent}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="email" name="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="password" name="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="submit" name="submit" value="Login" className="btn btn-success" />
                          {
                            showLoading ?

                              <Loader />
                              :

                              ''
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

