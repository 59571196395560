import React,{useState } from "react";
import { NavLink } from "react-router-dom";

const GalleryModal = ({ isOpen, close,imgsrc }) => {
    console.log(imgsrc);
  const [expanded, setExpanded] = useState(false);
  const handleNavbarItemClick = () => {
    // Hide the navbar after clicking an item
    setExpanded(false);
};
  if (!isOpen) return null;

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}>
      <div className="modalcontent">
       <img src={imgsrc} style={{width:"100%"}}/> 
        <button onClick={close} className="btn btn-danger">Close</button>
      </div>
    </div>
  );
};

export default GalleryModal;
