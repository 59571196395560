import React, { Component, useState } from "react";
import Slider from "../Component/Slider";
import Place1 from "../images/place1.jpg";
import Place2 from "../images/place2.jpg";
import Place3 from "../images/place3.jpg";
import Place4 from "../images/place4.jpg";
import Place5 from "../images/place5.jpg";
import Place6 from "../images/place6.jpg";
import Place7 from "../images/place7.jpg";
import Place8 from "../images/place8.jpg";
import Place9 from "../images/place9.jpg";
import Cuisine1 from "../images/cuisine1.jpg";
import Cuisine2 from "../images/cuisine2.jpg";
import Cuisine3 from "../images/cuisine3.jpg";
import Cuisine4 from "../images/cuisine4.jpg";
import Cuisine5 from "../images/cuisine5.jpg";
import Shop1 from "../images/shop1.jpg";
import Shop2 from "../images/shop2.jpg";
import Shop3 from "../images/shop3.jpg";
import Shop4 from "../images/shop4.jpg";
import Ayodhya from "../images/ayodhya.jpg";
import Agra from "../images/agra.jpg";
import Varanasi from "../images/varanasi.jpg";
import UserImg from "../images/user.jpg";
import Modal from 'react-bootstrap/Modal';
import { NavLink, Link, useNavigate } from "react-router-dom";
export const Cities = () => {
    const [ayodhya, setAyodhya] = useState(false);
    const [varanasi, setVaranasi] = useState(false);
    const [agra, setAgra] = useState(false);

    return (
        <>

            <Modal
                size="lg"
                show={ayodhya}
                onHide={() => setAyodhya(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Ayodhya
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Ayodhya} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Ayodhya is a city in the northern Indian state of Uttar Pradesh. It holds great religious significance for Hindus and is considered one of the seven holiest cities in Hinduism. The city is associated with the epic Ramayana, which is a sacred text in Hinduism.</p>
                        </div>
                        <div className="col-md-12">
                            <p className="mt-3">The major significance of Ayodhya lies in its association with Lord Rama, who is considered the seventh incarnation of Lord Vishnu in Hinduism. According to the Ramayana, Ayodhya was the capital of the ancient kingdom of Kosala, and it is believed to be the birthplace of Lord Rama. The city is also associated with other important figures from Hindu mythology, including King Dasharatha (Rama's father) and Sita (Rama's wife).</p>

                            <p>The Babri Masjid, a mosque that was built in Ayodhya in the 16th century, became a source of controversy and tension between Hindus and Muslims. The dispute over the site led to a series of legal and political battles. In 1992, the mosque was demolished by a mob of Hindu activists, leading to widespread communal tensions.</p>

                            <p>The Ayodhya dispute was a long-standing issue in India, and it was eventually settled by a Supreme Court judgment in November 2019. The court ruled in favor of the construction of a Hindu temple at the disputed site, while also directing the government to provide an alternative plot of land for the construction of a mosque.</p>

                            <p>The resolution of the Ayodhya dispute was a significant event in India's history, aiming to foster communal harmony and peace. The construction of the Ram Temple at the disputed site has been underway since then, and it holds immense religious and cultural importance for millions of Hindus. Ayodhya continues to be a prominent pilgrimage site for followers of Hinduism.</p>


                        </div>
                    </div>

                </Modal.Body>
            </Modal>




            <Modal
                size="lg"
                show={agra}
                onHide={() => setAgra(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Agra
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Agra} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Agra is a city located on the banks of the Yamuna River in the northern Indian state of Uttar Pradesh. It is renowned for its rich historical and architectural heritage, most notably the iconic Taj Mahal. Here are some key aspects of Agra:</p>
                        </div>
                        <div className="col-md-12">


                            <p><b>1- Taj Mahal:</b> Agra is best known for the Taj Mahal, a UNESCO World Heritage Site and one of the New Seven Wonders of the World. Commissioned by the Mughal Emperor Shah Jahan in memory of his beloved wife Mumtaz Mahal, the Taj Mahal is a white marble mausoleum known for its exquisite architecture and intricate craftsmanship.</p>

                            <p><b>2- Agra Fort:</b> Another UNESCO World Heritage Site, the Agra Fort is a historic fort that served as the main residence of the emperors of the Mughal Dynasty until the capital moved to Delhi. The fort is an impressive structure with well-preserved palaces, courtyards, and mosques.</p>

                            <p> <b>3- Fatehpur Sikri:</b> Located near Agra, Fatehpur Sikri is a UNESCO World Heritage Site and was the short-lived capital of the Mughal Empire during the reign of Emperor Akbar. The city is known for its well-preserved red sandstone architecture and important historical structures.</p>

                            <p><b>4- Sikandra:</b> The tomb of Akbar the Great, situated in Sikandra on the outskirts of Agra, is another notable Mughal architectural masterpiece. The tomb's design reflects a blend of Hindu, Christian, Islamic, Buddhist, and Jain motifs.</p>

                            <p><b>5- Chini Ka Rauza:</b> This Persian-style tomb is dedicated to the Prime Minister of the Mughal Emperor Shah Jahan, Allama Afzal Khan Mullah.</p>

                            <p><b>6- Yamuna River: </b> The city is situated on the banks of the Yamuna River, and the riverside provides a serene setting for various activities.</p>


                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={varanasi}
                onHide={() => setVaranasi(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Residency
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Varanasi} width="100%" />
                        </div>
                        <div className="col-md-6">
                            <p className="mt-3">Varanasi, also known as Banaras or Kashi, is one of the oldest continuously inhabited cities in the world and holds immense cultural, religious, and historical significance in India. Situated on the banks of the Ganges River in the northern state of Uttar Pradesh, Varanasi is considered a sacred city in Hinduism and is a major pilgrimage destination. Here are some key aspects of Varanasi:</p>
                        </div>
                        <div className="col-md-12">


                            <p><b>1- Spiritual Significance:</b> Varanasi is one of the holiest cities in Hinduism, and it is believed to be the abode of Lord Shiva. The city is a major center for pilgrimage and religious ceremonies. The ghats (steps leading to the river) along the Ganges are considered sacred, and performing rituals and ceremonies here is believed to purify the soul.</p>

                            <p><b>2- Ghats of Varanasi:</b> The city is famous for its numerous ghats along the Ganges River. These ghats serve various purposes, including bathing, cremation, and religious ceremonies. Dashashwamedh Ghat is particularly renowned for the Ganga Aarti, a spectacular religious ritual performed every evening.</p>

                            <p> <b>3- Kashi Vishwanath Temple:</b> Dedicated to Lord Shiva, the Kashi Vishwanath Temple is one of the most important and revered temples in Varanasi. It is believed that a visit to this temple and a dip in the Ganges can lead to moksha, or liberation from the cycle of birth and death.</p>

                            <p><b>4- Sarnath:</b> Located near Varanasi, Sarnath is an important Buddhist pilgrimage site. It is where Lord Buddha gave his first sermon after attaining enlightenment. The Dhamek Stupa and the Ashoka Pillar are key attractions in Sarnath.</p>

                            <p><b>5- Benares Hindu University (BHU):</b> Established in 1916, BHU is one of the oldest and most prestigious universities in India. It has played a significant role in the cultural and educational development of Varanasi.</p>

                            <p><b>6- Silk Weaving Industry:</b> Varanasi is famous for its silk weaving industry, and Banarasi silk sarees are renowned for their intricate designs and craftsmanship.</p>
                            <p><b>7- Ramnagar Fort:</b> Situated on the eastern bank of the Ganges River, the Ramnagar Fort is an ancient fort that serves as the residence of the Maharaja of Varanasi. It houses a museum displaying vintage cars, royal costumes, and other artifacts.</p>


                        </div>
                    </div>

                </Modal.Body>
            </Modal>





            <div className="w3l-homeblog" id="">
                <div className="container py-lg-5 py-md-4">
                    <div className="header-title">
                        <span className="sub-title">NEAR BY CITIES TO VISIT</span>

                    </div>
                    <div className="row top-pics ">

                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Ayodhya} alt="" className="img-fluid" />
                            <p className="title">Ayodhya</p>
                            <p className="distance"> 136 Km. from Lucknow</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setAyodhya(true)}> Read More.. </a></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Agra} alt="" className="img-fluid" />
                            <p className="title">Agra</p>
                            <p className="distance"> 335 Km. from Lucknow</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setAgra(true)}> Read More.. </a></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-5 places">
                            <img src={Varanasi} alt="" className="img-fluid" />
                            <p className="title">Varanasi</p>
                            <p className="distance"> 312 Km. from Lucknow</p>
                            <div className="overlay"></div>
                            <div className="placelink"><a onClick={() => setVaranasi(true)}> Read More.. </a></div>
                        </div>


                    </div>
                </div>
            </div>








        </>
    );


}
export default Cities;