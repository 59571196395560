import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import { Link, useNavigate } from "react-router-dom";
import Constants from "../../utils/Constants";


export const Dashboard = () => {
    const navigate = useNavigate();
    const [students, setStudents] = useState(0);
    const [faculty, setFaculty] = useState(0);
    const [foreign, setForeign] = useState(0);
    const [accomp, setAccomp] = useState(0);
    const [poster, setPoster] = useState(0);
    const [paper, setPaper] = useState(0);
    const [quiz, setQuiz] = useState(0);

    if (!localStorage.getItem('user')) {
        console.log("yesy");
        navigate("/");
    }
    useEffect(() => {
        fetchDashboard();
    }, [])
    const fetchDashboard = async () => {
        await axios.get(Constants.baseUrl + "dashboarddata")
            .then((res) => {
                setStudents(res.data.data.students)
                setFaculty(res.data.data.faculty)
                setForeign(res.data.data.foreign)
                setAccomp(res.data.data.accompanying)
                setPoster(res.data.data.posters)
                setPaper(res.data.data.paper)
                setQuiz(res.data.data.quiz)
            })
            .catch((error) => {
                console.error(error);
            });
    }
    return (
        <>
            <div className="inner-banner">
                <section className="w3l-breadcrumb text-left">
                    <div className="container">
                        <ul className="breadcrumbs-custom-path">
                            <li><Link to="/dashboard">HOME</Link></li>
                            <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> DASHBOARD</li>
                        </ul>
                    </div>
                </section>
            </div>
            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="row">

                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">REGISTRATIONS</span>

                                </div>

                                <div className="col-md-12 text-left mt-5">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="dashboardlinks">
                                                <Link to="/facultylist">({faculty})<br /> FACULTIES</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashboardlinks">
                                                <Link to="/registrations">({students})<br /> STUDENTS</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashboardlinks">
                                                <Link to="/foreignlist">({foreign})<br /> FOREIGN DELEGATES</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dashboardlinks">
                                                <Link to="/accomplist">({accomp})<br /> Accompanying Person</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>


                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">COMPETITION ENTRIES</span>

                                </div>

                                <div className="col-md-12 text-left mt-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="dashboardlinks">
                                                <Link to="/papers">({paper})<br /> PAPERS</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="dashboardlinks">
                                                <Link to="/posters">({poster})<br /> POSTERS</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="dashboardlinks">
                                                <Link to="/quiz">({quiz})<br /> QUIZ</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default Dashboard;