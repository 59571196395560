import React, { Component } from "react";
import Abhisheksinha from '../images/abhisheksinha.jpg';
import Sunitasrivastava from '../images/sunitasrivastava.jpg';
import Neetamishra from '../images/neetamishra.jpeg';
import Shrikishna from '../images/srikrishna.jpeg';
import Sandeeppagare from '../images/sandeeppagare.jpeg'
import Sarvanabharathi from '../images/Sarvanabharthi.jpeg'
import Sridevi from '../images/Sridevi.jpeg'
import Sunali from '../images/Sunali.jpeg'
import Prasanthi from '../images/Prasanthi.jpeg'
import Adityasrivastava from '../images/Adityasrivastava.jpeg'
import Avinashtejasvi from '../images/Avinashtejasvi.jpeg'
import Male from '../images/male.png'
import Ramamurthy from '../images/Ramamurthy.jpeg'
import Deepakta from '../images/Deepakta.jpeg'
import Manisham from '../images/Manisham.jpeg'
import Gvpramod from '../images/Gvpramod.jpeg'
import Ranjeeta from '../images/Ranjeetamehta.jpeg'
import Pawankumar from '../images/Pawankumar.jpeg'
import Sarvanan from '../images/Sarvanan.jpeg'
import Kotya from '../images/Kotya.jpeg'
import Ram from '../images/Ram.jpeg'
import Rachna from '../images/Rachna.jpeg'
import Utkal from '../images/Utkal.jpeg'
import Deepa from '../images/Deepa.jpeg'
import Nikhil from '../images/Nikhil.jpeg'
import Shiva from '../images/Shiva.jpeg'
import Ashokl from '../images/Ashokl.jpeg'
import M59 from '../images/m59.jpeg'

class Bearers extends Component {
  render() {
    return (
      <>
        <div className="inner-banner">
          <section className="w3l-breadcrumb text-left">
            <div className="container">
              <ul className="breadcrumbs-custom-path">
                <li><a href="index.html">HOME</a></li>
                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> OFFICE BEARERS OF IAOMR</li>
              </ul>
            </div>
          </section>
        </div>
        <section className="w3l-index3" id="about1">
          <div className="midd-w3 py-5">
            <div className="container py-lg-5 py-md-3">
              <div className="row">
                <div className="col-lg-12 themeheading">
                  <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                </div>
                <div className="col-lg-12 pl-lg-5 mb-5">
                  <div className="header-title">
                    <span className="sub-title">OFFICE BEARERS OF IAOMR</span>

                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">
                      <div className="col-lg-3  mb-5 mx-auto">
                        <img src={M59} width="100%" className="doctor" />
                        <h3 className="comitteenames">DR. SATHEESHA REDDY B H</h3>
                        <p className="comitteeposts">IMMEDIATE PAST PRESIDENT</p>
                      </div>
                      <div className="col-lg-3  mb-5 mx-auto">
                        <img src={Neetamishra} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NEETA MISRA</h3>
                        <p className="comitteeposts">PRESIDENT</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Shrikishna} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SRI KRISHNA</h3>
                        <p className="comitteeposts">PRESIDENT-ELECT</p>
                      </div>
                    </div>
                  </div>



                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">

                      <div className="col-lg-3  mb-5 mx-auto">
                        <img src={Sandeeppagare} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SANDEEP PAGARE </h3>
                        <p className="comitteeposts">VICE PRESIDENT</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Abhisheksinha} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ABHISHEK SINHA</h3>
                        <p className="comitteeposts">VICE PRESIDENT</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Sarvanabharathi} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SARAVANA BHARATHI</h3>
                        <p className="comitteeposts">VICE PRESIDENT</p>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Sridevi} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SRIDEVI K.</h3>
                        <p className="comitteeposts">VICE PRESIDENT</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Sunali} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SUNALI KHANNA</h3>
                        <p className="comitteeposts">VICE PRESIDENT</p>
                      </div>


                    </div>
                  </div>


                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">
                      <div className="col-lg-3  mb-5 mx-auto">
                        <img src={Shiva} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. SHIVAPRASAD S.</h3>
                        <p className="comitteeposts">HON. GEN. SECRETARY</p>
                      </div>
                      <div className="col-lg-3  mb-5 mx-auto">
                        <img src={Adityasrivastava} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ADIT SRIVASTAVA</h3>
                        <p className="comitteeposts">HEAD OFFICE JOINT SECRETARY</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Prasanthi} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. PRASHANTHI REDDY</h3>
                        <p className="comitteeposts">HEAD OFFICE JOINT SECRETARY</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Avinashtejasvi} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. AVINASH TEJASVI</h3>
                        <p className="comitteeposts">HEAD OFFICE TREASURER</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Ramamurthy} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RAMAMURTHY T K</h3>
                        <p className="comitteeposts">REGISTERED OFFICE SECRETARY</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Ashokl} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. ASHOK L</h3>
                        <p className="comitteeposts">REGISTERED OFFICE JOINT SECRETARY</p>
                      </div>

                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Deepakta} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DEEPAK T A</h3>
                        <p className="comitteeposts">REGISTERED OFFICE TREASURER</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Manisham} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. MANISHA M. KHORATE</h3>
                        <p className="comitteeposts">EDITOR IN CHIEF</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 pl-lg-5 mb-5 mt-5">
                    <div className="row">





                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Gvpramod} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. GV PRAMOD</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Ranjeeta} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RANJEETA MEHTA</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Pawankumar} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. Y. PAVAN KUMAR</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Sarvanan} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. T. SARAVANAN</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Kotya} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. KOTYA NAIK MALOTH</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Ram} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RAMNARAYAN B.K</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Rachna} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. RACHNA PRABHU</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Utkal} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. UTHKAL M.P</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Deepa} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. DEEPA M. S</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>
                      <div className="col-lg-3 mb-5 mx-auto">
                        <img src={Nikhil} width="100%" className="doctor" />
                        <h3 className="comitteenames">Dr. NIKHIL RAJ</h3>
                        <p className="comitteeposts">EXECUTIVE COMMITTEE MEMBER</p>
                      </div>

                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default Bearers;