import React, { Component } from "react";
class AboutVenue extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb text-left">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="/">HOME</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> ABOUT VENUE</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-index3" id="about1">
                    <div className="midd-w3 py-5">
                        <div className="container py-lg-5 py-md-3">
                            <div className="row">

                                <div className="col-lg-12 themeheading">
                                    <h1>"Embracing the Future : Exploring Trends and Insights"</h1>
                                </div>
                                <div className="col-lg-12 pl-lg-5 mb-5">
                                    <div className="header-title">
                                        <span className="sub-title">ABOUT VENUE</span>

                                    </div>

                                    <div className="col-md-12 text-left">
                                        <p className="mt-3">Welcome to the distinguished <b>Sardar Patel Post Graduate Institute of Dental and Medical Sciences</b>, a leading dental institution that has consistently produced outstanding professionals over the past three decades. As one of the pioneers, we were among the first private dental institutions of North India to receive recognition in all nine specialities of dentistry, welcoming an annual intake of 100 BDS (Undergraduate) students. Our establishment in 1996, under the visionary leadership of Dr. O.P. Chaudhary, is a tribute to Sardar Vallabh Bhai Patel, the Iron Man of India, who served as a perpetual inspiration to our honorable chairman.
                                        </p>
                                        <p className="mt-3">Nestled in the heart of <b>Lucknow</b>, the capital city of Uttar Pradesh, our institute is strategically located in a vibrant and culturally rich environment.  <b>Lucknow</b>, evolving into one of India's fastest-growing non-major-metropolitan cities, stands as a center of governance, education, healthcare and culture. It continues to thrive, contributing significantly to India's growth story.
                                        </p>
                                        <p className="mt-3">
                                            <b>Lucknow</b>, situated at the central juncture of Uttar Pradesh, offers a splendid opportunity for visitors to not only relish the enchanting city itself but also explore nearby historic cities such as Ayodhya, Agra and Varanasi. Additionally, one can partake in a delightful spiritual tour by visiting the enchanting cities of Ayodhya and Varanasi.

                                        </p>
                                        <p className="mt-3">
                                            Our institute takes pride in its highly experienced faculty members who exhibit unwavering dedication to research, innovation and academic excellence. Their commitment extends to empowering each student with substantial clinical exposure and expertise. At <b>Sardar Patel Post Graduate Institute of Dental and Medical Sciences</b>, we invite you to embark on a journey of academic excellence and healthcare innovation.

                                        </p>
                                        <p className="mt-3">We warmly invite you to join us at the <b>Sardar Patel Post Graduate Institute of Dental and Medical Sciences</b> for the National PG Convention. Immerse yourself in a journey of academic excellence featuring captivating workshops, enlightening guest lectures by legends in our field and much more. This event provides a unique opportunity to foster and revitalize new ideas within the speciality of Oral Medicine and Radiology.</p>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default AboutVenue;