import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import Dropzone from "react-dropzone";
import Constants from "../utils/Constants";
import Loader from "../utils/Loader";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QR from '../images/qrcode.png';
import Radio from '@mui/material/Radio';
import { History } from "@remix-run/router";
import { useParams } from "react-router-dom";
import { NavLink, Link, useNavigate } from "react-router-dom";
export const Details = () => {
    const [name, setName] = useState('');
    const [registrationId, setRegistrationId] = useState('');
    const [photo, setPhoto] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [institute, setInstitute] = useState('');
    const [faculty, setFaculty] = useState('');
    const [membertype, setMembertype] = useState('');
    const [registrationno, setRegistrationno] = useState('');
    const [transactiondetails, setTransactiondetails] = useState('');
    const [transactiondate, setTransactiondate] = useState('');
    const [paymentproof, setPaymentproof] = useState('');
    const { id } = useParams();
    const { type } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        fetchRegisters();
    }, [])

    const fetchRegisters = async () => {
        await axios.get(Constants.baseUrl + "registration-details?id=" + id + "&type=" + type)
            .then((response) => {
                setName(response.data.name);
                setRegistrationId(response.data.registration_id);
                setPhoto(response.data.photo);
                setGender(response.data.gender);
                setAddress(response.data.address);
                setMobile(response.data.mobile);
                setEmail(response.data.email);
                setInstitute(response.data.institute);
                setFaculty(response.data.faculty);
                setMembertype(response.data.membertype);
                setRegistrationno(response.data.registration_no);
                setTransactiondetails(response.data.transaction_details);
                setTransactiondate(response.data.transaction_date);
                setPaymentproof(response.data.payment_proof);

            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <>


            <section className="w3l-index3" id="about1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="row">

                            <div className="col-lg-12 pl-lg-5 mb-5">
                                <div className="header-title">
                                    <span className="sub-title">Registration Details</span>

                                </div>

                                <div className="col-md-12 text-left">
                                    <table className="table">
                                        <tr>
                                            <td><b>Registration ID:</b> {registrationId}</td>
                                            <td><b>Name:</b> {name}</td>


                                            <td rowSpan={4}>
                                                <img src={photo} width="200"></img>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td><b>Gender:</b> {gender}</td>
                                            <td><b>Mobile:</b> {mobile}</td>




                                        </tr>
                                        <tr>

                                            <td><b>Email Id:</b> {email}</td>
                                            <td><b>Institute Name:</b> {institute}</td>



                                        </tr>
                                        <tr>
                                            <td><b>Address:</b> {address}</td>
                                            <td><b>Faculty/Student:</b> {faculty}</td>


                                        </tr>
                                        <tr>
                                            <td><b>Member Type:</b> {membertype}</td>
                                            <td><b>Membership Number:</b> {registrationno}</td>

                                        </tr>
                                        <tr>

                                            <td><b>Transaction ID:</b> {transactiondetails}</td>
                                            <td><b>Transaction Date:</b> {transactiondate}</td>
                                        </tr>
                                        <tr>

                                            <td colSpan={2}><b>Payment Proof:</b><br /> <img src={paymentproof} width="400"></img></td>

                                        </tr>
                                    </table>
                                </div>

                                <div className="col-md-12 text-center">
                                    <Button onClick={() => navigate(-1)} className="btn btn-success"> Go back </Button>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );

}
export default Details;