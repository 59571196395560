import React, { Component } from "react";

import { NavLink, Link } from "react-router-dom";

class Contact extends Component {
    render() {
        return (
            <>
                <div className="inner-banner">
                    <section className="w3l-breadcrumb">
                        <div className="container">
                            <ul className="breadcrumbs-custom-path">
                                <li><a href="index.html">Home</a></li>
                                <li className="active"><span className="fa fa-chevron-right mx-2" aria-hidden="true"></span> Contact</li>
                            </ul>
                        </div>
                    </section>
                </div>
                <section className="w3l-contact-1 py-5" id="contact">
                    <div className="contacts-9 py-lg-5 py-md-4">
                        <div className="container">
                            <div className="d-grid contact-view mb-5 pb-lg-5">
                                <div className="cont-details">
                                    <div className="contactct-fm-text text-left mb-md-5 mb-4">
                                        <div className="header-title mb-md-5 mt-4">
                                            <span className="sub-title">Contact Us</span>

                                        </div>


                                    </div>
                                    <div className="cont-top">
                                        <div className="cont-left text-center">
                                            <span className="fa fa-phone"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h6>Phone number</h6>
                                            <p><b>Dr Abhishek Sinha (Organizing Chairman)</b> <br />   <a href="tel:+91-8948116600">+91-8948116600</a><br />
                                                <b>Dr Sunita Srivastava (Organizing Secretary)</b><br />
                                                <a href="tel:+91-7607722454" >+91-7607722454</a><br />
                                                <b>Dr Anuj Mishra (Conference Treasurer)</b><br /><a href="tel:+91-8127005593" >+91-8127005593</a></p>
                                        </div>
                                    </div>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fa fa-envelope-o"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h6>Send Email</h6>
                                            <p><a href="mailto:iaomrpgconvention2024@gmail.com" className="mail">iaomrpgconvention2024@gmail.com</a></p>
                                        </div>
                                    </div>
                                    <div className="cont-top margin-up">
                                        <div className="cont-left text-center">
                                            <span className="fa fa-map-marker"></span>
                                        </div>
                                        <div className="cont-right">
                                            <h6>Address</h6>
                                            <p className="pr-lg-5">Department of Oral Medicine and Radiology
                                                Sardar Patel PG Institute of Dental and Medical Sciences
                                                Chaudhary Vihar, Raebareilly Road, Uterethia, Lucknow - 226029</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="map-content-9">
                                    <div className="contactct-fm map-content-9 pl-lg-4">
                                        <div className="contactct-fm-text text-left">
                                            <div className="header-title mb-md-5 mt-4">
                                                <span className="sub-title">Enquiry Form</span>

                                            </div>

                                        </div>
                                        <form action="https://sendmail.w3layouts.com/submitForm" method="post">
                                            <div className="twice">
                                                <input type="text" className="form-control" name="w3lName" id="w3lName"
                                                    placeholder="Name" required="" />

                                            </div>
                                            <div className="twice">
                                                <input type="text" className="form-control" name="w3lName" id="w3lName"
                                                    placeholder="Email" required="" />

                                            </div>
                                            <div className="twice">
                                                <input type="text" className="form-control" name="w3lSubject" id="w3lSubject"
                                                    placeholder="Mobile No" required="" />
                                            </div>
                                            <textarea name="w3lMessage" className="form-control" id="w3lMessage" placeholder="Enquiry"
                                                required=""></textarea>
                                            <div className="text-right">
                                                <button type="submit" className="btn btn-primary btn-style mt-4">Send Message</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section></>
        );

    }
}
export default Contact;