import logo from './logo.svg';
import './App.css';
import Header from './Component/Header';
import Home from './Pages/Home';
import Footer from './Component/Footer';
import Contact from './Pages/Contact';
import Bearers from './Pages/Bearers';
import OrganizingCommittee from './Pages/OrganizingCommittee';
import RegistrationDetails from './Pages/RegistrationDetails';
import ScientificSchedule from './Pages/ScientificSchedule';
import ScientificGuidelines from './Pages/ScientificGuidelines';
import OnlineRegistration from './Pages/OnlineRegistration';
import AdvisoryBoard from './Pages/AdvisoryBoard';
import DebateCompetition from './Pages/DebateCompetition';
import Speakers from './Pages/Speakers';
import SubmitAbstract from './Pages/SubmitAbstract';
import PreConvention from './Pages/PreConvention';
import AboutVenue from './Pages/AboutVenue';
import PlacesToVisit from './Pages/PlacesToVisit';
import FamousCuisines from './Pages/FamousCuisines';
import NearByCities from './Pages/NearByCities';
import Hotels from './Pages/Hotels';
import Dashboard from './Pages/admin/Dashboard';
import Thanks from './Pages/Thanks';
import { Login } from './Pages/Login';
import AdminHeader from './Component/AdminHeader';
import Registrationlist from './Pages/Registrationlist';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import FacultyList from './Pages/admin/FacultyList';
import AccompList from './Pages/admin/AccompList';
import Foreign from './Pages/admin/ForeignList';
import Papers from './Pages/admin/Papers'
import Posters from './Pages/admin/Posters'
import Quiz from './Pages/admin/Quiz'
import Details from './Pages/Details';
import IDCards from './Pages/admin/IDCards';
import { NationalCoordinators } from './Pages/NationalCoordinators';
import { DownloadAdmitCard } from './Pages/admin/DownloadAdmitCard';

function Layout({ children }) {
  const location = useLocation();
  const noHeaderFooterRoutes = ['/download-admit-card'];

  const hideHeaderFooter = noHeaderFooterRoutes.some(route => location.pathname.startsWith(route));

  return (
    <>
      {!hideHeaderFooter && <Header />}
      {children}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  
  return (
    <HttpsRedirect>
      <div className="App">
        <Router>

        
          <Layout>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/papers' element={<Papers />} />
            <Route exact path='/registration-details/:id/:type' element={<Details />} />
            <Route exact path='/posters' element={<Posters />} />
            <Route exact path='/quiz' element={<Quiz />} />
            <Route exact path='/facultylist' element={<FacultyList />} />
            <Route exact path='/national-coordinators' element={<NationalCoordinators />} />
            <Route exact path='/accomplist' element={<AccompList />} />
            <Route exact path='/foreignlist' element={<Foreign />} />
            <Route exact path='/office-bearers' element={<Bearers />} />
            <Route exact path='/organizing-committee' element={<OrganizingCommittee />} />
            <Route exact path='/scientific-schedule' element={<ScientificSchedule />} />
            <Route exact path='/scientific-guidelines' element={<ScientificGuidelines />} />
            <Route exact path='/patrons' element={<AdvisoryBoard />} />
            <Route exact path='/speakers' element={<Speakers />} /> 
            <Route exact path='/debate-competiton' element={<DebateCompetition/>} />
            <Route exact path='/submit-abstract' element={<SubmitAbstract />} />
            <Route exact path='/preconvention-course' element={<PreConvention />} />
            <Route exact path='/about-venue' element={<AboutVenue />} />
            <Route exact path='/places-to-visit' element={<PlacesToVisit />} />
            <Route exact path='/famous-cuisines' element={<FamousCuisines />} />
            <Route exact path='/nearby-cities-to-visit' element={<NearByCities />} />
            <Route exact path='/list-of-hotels' element={<Hotels />} />
            <Route exact path='/thanks/:id' element={<Thanks />} />

            <Route exact path='/registration-details' element={<RegistrationDetails />} />
            <Route exact path='/online-registration' element={<OnlineRegistration />} />
            <Route exact path='/contact-us' element={<Contact />} />
            <Route path='/admin-login' element={<Login />} />

          </Routes>

          <Routes>

            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/registrations' element={<Registrationlist />} />
            <Route path='/id-cards' element={<IDCards/>}/>
            <Route path='/download-admit-card/:id' element={<DownloadAdmitCard/>}/>

          </Routes>
          </Layout>
        </Router>
       
      </div>
    </HttpsRedirect>
  );
}

export default App;
