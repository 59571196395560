import React, { Component, useEffect, useState } from "react";
import Logo from '../images/logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link, useNavigate } from "react-router-dom";
import Otherlogo from '../images/otherlogo.jpg';
import Brochure from '../brochure.pdf';
import CountdownTimer from './CountdownTimer';
export const Header = () => {
    const THREE_DAYS_IN_MS = 164 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
    const [expanded, setExpanded] = useState(false);

    const handleNavbarToggle = () => {
        // Toggle the collapse state
        setExpanded(!expanded);
    };

    const handleNavbarItemClick = () => {
        // Hide the navbar after clicking an item
        setExpanded(false);
    };

    const downLoadBrochure = (e) => {
        e.preventDefault();
        const pdfUrl = "../brochure.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "../brochure.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        navigate("/admin-login");
    }



    return (

        <header id="site-header" >
            <div className="container-fluid  ">
                <div className="row countdown">
                    <div className="col-md-8">
                        <marquee className="mtext"> 
                            Faculty members who wish to visit Ayodhya on Sunday, 25<sup>th</sup> August 2024 are advised to contact the Organizing Chairman.</marquee>
                    </div>
                    <div className="col-md-4 timer">
                        <CountdownTimer targetDate={new Date('2024-08-22T10:00:00')} /> 
                    </div>
                </div>
            </div>
            <div className="container">

                <nav className="navbar navbar-expand-lg navbar-dark stroke">



                    <Navbar expand="lg" expanded={expanded} onToggle={handleNavbarToggle}>
                        <NavLink to="/" className="nav-link" onClick={handleNavbarItemClick}><img src={Logo} alt="Your logo" title="Your logo" /></NavLink>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                {!localStorage.getItem('user') ? (
                                    <>
                                        <NavLink to="/" className="nav-link" onClick={handleNavbarItemClick}>Home</NavLink>
                                        <NavDropdown title="Committee" id="basic-nav-dropdown" >
                                            <NavLink to="/patrons" className="dropdown-item" onClick={handleNavbarItemClick}>Patrons</NavLink>

                                            <NavLink to="/organizing-committee" className="dropdown-item" onClick={handleNavbarItemClick}>Organizing Committee</NavLink>
                                            <NavLink to="/national-coordinators" className="dropdown-item" onClick={handleNavbarItemClick}>National Coordinators</NavLink>
                                            <NavLink to="/office-bearers" className="dropdown-item" onClick={handleNavbarItemClick}>IAOMR Office Bearers</NavLink>


                                        </NavDropdown>
                                        <NavDropdown title="Registration" id="basic-nav-dropdown">
                                            <NavLink to="/registration-details" className="dropdown-item" onClick={handleNavbarItemClick}>Registration Details</NavLink>
                                            <NavLink to="/online-registration" className="dropdown-item" onClick={handleNavbarItemClick}>Online Registration</NavLink>

                                        </NavDropdown>
                                        <NavDropdown title="Scientific" id="basic-nav-dropdown">
                                            <NavLink to="/speakers" className="dropdown-item" onClick={handleNavbarItemClick}>Speakers</NavLink>

                                            <NavLink to="/scientific-schedule" className="dropdown-item" onClick={handleNavbarItemClick}> Scientific Schedule</NavLink>
                                            <NavLink to="/scientific-guidelines" className="dropdown-item" onClick={handleNavbarItemClick}> Scientific Guidelines</NavLink>
                                            <NavLink to="/submit-abstract" className="dropdown-item" onClick={handleNavbarItemClick}> Upload Form for Paper/Poster/Quiz</NavLink>



                                            <NavLink to="/preconvention-course" className="dropdown-item" onClick={handleNavbarItemClick}> Pre-Convention Course</NavLink>

                                            <NavLink to="/debate-competiton" className="dropdown-item" onClick={handleNavbarItemClick}> Debate Competition</NavLink>

                                        </NavDropdown>
                                        <NavDropdown title="Venue" id="basic-nav-dropdown">
                                            <NavLink to="/about-venue" className="dropdown-item" onClick={handleNavbarItemClick}> About Venue</NavLink>
                                            <NavLink to="/places-to-visit" className="dropdown-item" onClick={handleNavbarItemClick}> Places To Visit</NavLink>
                                            <NavLink to="/famous-cuisines" className="dropdown-item" onClick={handleNavbarItemClick}> Famous Cuisines</NavLink>
                                            <NavLink to="/nearby-cities-to-visit" className="dropdown-item" onClick={handleNavbarItemClick}> Nearby Cities to Visit</NavLink>

                                        </NavDropdown>
                                        <NavDropdown title="Accomodation" id="basic-nav-dropdown">
                                            <NavLink to="/list-of-hotels" className="dropdown-item" onClick={handleNavbarItemClick}> List of Hotels</NavLink>
                                        </NavDropdown>
                                        <NavLink to="/contact-us" className="nav-link" onClick={handleNavbarItemClick}> Contact</NavLink>
                                        <NavDropdown title="Downloads" id="basic-nav-dropdown">
                                            <NavDropdown.Item href="#action/3.1" onClick={handleNavbarItemClick}>E- Souvenir</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.1" onClick={handleNavbarItemClick}>Photographs</NavDropdown.Item>
                                            <NavLink to="/#" onClick={downLoadBrochure} download className="dropdown-item">Brochure</NavLink>
                                        </NavDropdown>
                                        <NavLink to="/admin-login" className="nav-link" onClick={handleNavbarItemClick}>Login</NavLink>
                                    </>
                                ) : <>
                                    <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                                    <NavDropdown title="Registrations" id="basic-nav-dropdown">
                                        <NavLink to="/registrations" className="dropdown-item">Students</NavLink>
                                        <NavLink to="/facultylist" className="dropdown-item">Faculties</NavLink>
                                        <NavLink to="/accomplist" className="dropdown-item">Accompanying Persons</NavLink>
                                        <NavLink to="/foreignlist" className="dropdown-item">Foreign Delegates</NavLink>


                                    </NavDropdown>
                                    <NavLink to="/papers" className="nav-link">Papers</NavLink>
                                    <NavLink to="/posters" className="nav-link">Posters</NavLink>
                                    <NavLink to="/quiz" className="nav-link">Quiz</NavLink>
                                    <NavLink to="/id-cards" className="nav-link">ID Cards</NavLink>
                                    <NavLink onClick={handleLogout} className="nav-link">Logout</NavLink>
                                </>}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>









                </nav>
            </div>
        </header >
    );

}
export default Header;